var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ShiftAtom', {
    ref: 'wrapper',
    attrs: {
      "isScroll": _vm.isScroll
    }
  }, [_c('ShiftPlanHeader', [_c('div', {
    staticClass: "shift-plan-header name"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.shiftName')))]), _c('div', {
    staticClass: "shift-plan-header times"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.shiftTimes')))])]), _vm._l(_vm.shifts, function (shift) {
    return _c('ShiftPlanRow', {
      key: shift.id
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.tooltip(shift, 'click'),
        expression: "tooltip(shift, 'click')"
      }],
      staticClass: "row-name",
      class: {
        tooltip: shift.hasTooltip
      }
    }, [_vm._v(_vm._s(shift.name))]), _c('div', {
      staticClass: "row-content"
    }, [_c('Shift', {
      on: {
        "click": function click($event) {
          return _vm.$emit('toggle-shift-selected', shift);
        }
      }
    }, [_c('span', {
      staticClass: "time"
    }, [_vm._v(_vm._s(_vm._f("timeSimple")(shift.start, _vm.locale)) + " - " + _vm._s(_vm._f("timeSimple")(shift.end, _vm.locale)))]), _c('ShiftMarker', {
      class: _vm.isShiftChecked(shift) ? 'marker-active' : 'marker-inactive'
    }, [_c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [_c('CheckIcon', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShiftChecked(shift),
        expression: "isShiftChecked(shift)"
      }],
      staticClass: "icon"
    })], 1)], 1)], 1)], 1)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }