<template>
  <TimepickerContentMolecule>
    <CalendarMolecule
      :firstMondayInView="firstMondayInView"
      :selectionStart="selectionStart"
      :selectionEnd="selectionEnd"
      :isSelecting="isSelecting"
      :hasSelection="hasSelection"
      :highlightedInterval="highlightedInterval"
      :localSelectedInterval="localSelectedInterval"
      :localSelectedTimezoneOption="localSelectedTimezoneOption"
      :localShiftFiltersEnabled="localShiftFiltersEnabled"
      :localSelectedShifts="localSelectedShifts"
      :selectedInterval="selectedInterval"
      :selectedShifts="selectedShifts"
      :selectedTimezone="selectedTimezone"
      :selectableDateInterval="selectableDateInterval"
      :hasShiftFilter="hasShiftFilter"
      :canConfirm="canConfirm"
      :canGoPrev="canGoPrev"
      :canGoNext="canGoNext"
      :futureDateDisabled="futureDateDisabled"
      @handleMouseWheel="handleMouseWheel"
      @mousedown="mousedown"
      @mousemove="mousemove"
      @mouseLeave="mouseLeave"
      @touchStart="touchStart"
      @touchEnd="touchEnd"
      @handleHover="handleHover"
      @touchMove="touchMove"
      @setHighlightedInterval="highlightedInterval = $event"
      @updateSelectionToInterval="updateSelectionToInterval"
    />
    <div class="extra-options">
      <TimepickerTimezoneMolecule
        :localSelectedTimezoneOption="localSelectedTimezoneOption"
        :availableTimezones="availableTimezones"
        @set-selected-timezone="setSelectedTimezone"
      />
      <TimepickerShiftFiltersMolecule
        v-if="hasShiftFilter"
        :shiftFiltersEnabled="localShiftFiltersEnabled"
        :availableShiftplans="availableShiftplans"
        :selectedShiftplan="localSelectedShiftplan"
        :selectedShifts="localSelectedShifts"
        @toggle-shift-filters-enabled="localShiftFiltersEnabled = !localShiftFiltersEnabled"
        @set-selected-shiftplan="setLocalSelectedShiftplan"
        @toggle-shift-selected="toggleLocalSelectedShift"
      />
    </div>
    <TimepickerFooterMolecule
      :canConfirm="canConfirm"
      :canGoPrev="canGoPrev"
      :canGoNext="canGoNext"
      @confirmSelection="confirmSelection"
      @abortSelection="abortSelection"
      @prevWeek="prevWeek"
      @nextWeek="nextWeek"
    />
  </TimepickerContentMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Interval } from 'luxon'
import throttle from 'lodash/throttle'

import CalendarMolecule from './TimepickerContentMolecule/CalendarMolecule.vue'
import TimepickerFooterMolecule from './TimepickerContentMolecule/TimepickerFooterMolecule.vue'
import TimepickerShiftFiltersMolecule from './TimepickerContentMolecule/TimepickerShiftFiltersMolecule.vue'
import { cloneDeep, isEqual } from 'lodash'
import TimepickerTimezoneMolecule from './TimepickerContentMolecule/TimepickerTimezoneMolecule.vue'

const TimepickerContentMolecule = styled('div')`
  position: absolute;
  width: 360px;
  max-width: calc(100vw - 52px);
  max-height: calc(100vh - 170px);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 280px min-content 40px;
  border: 1px solid ${p => p.theme.colors.panelBorder};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};

  @media (max-width: 767px) {
    left: 0;
  }
`

export default {
  props: {
    selectedInterval: {
      type: Object,
      required: true,
    },
    selectedShifts: {
      type: Array,
      required: true,
    },
    selectedTimezone: {
      type: String,
      required: true,
    },
    selectedTimezoneId: {
      type: String,
      required: true,
    },
    hasShiftFilter: {
      type: Boolean,
      required: true,
    },
    availableShiftplans: {
      type: Array,
      required: true,
    },
    selectedShiftplan: {
      type: Object,
    },
    shiftFiltersEnabled: {
      type: Boolean,
      required: true,
    },
    availableTimezones: {
      type: Array,
      required: true,
    },
    futureDateDisabled: {
      type: Boolean,
      required: true,
    },
    maxSelectableIntervalInDays: {
      type: Number,
    },
  },
  components: {
    TimepickerContentMolecule,
    CalendarMolecule,
    TimepickerFooterMolecule,
    TimepickerShiftFiltersMolecule,
    TimepickerTimezoneMolecule,
  },
  data() {
    return {
      firstMondayInView: this.selectedInterval.start
        .setZone(this.selectedTimezone)
        .startOf('month')
        .minus({ days: Math.abs(1 - this.selectedInterval.start.startOf('month').weekday) }),
      selectionStart: null,
      selectionEnd: null,
      isSelecting: false,
      highlightedInterval: null,
      localShiftFiltersEnabled: !!this.shiftFiltersEnabled,
      localSelectedShifts: cloneDeep(this.selectedShifts),
      localSelectedShiftplan: cloneDeep(this.selectedShiftplan),
      localSelectedTimezoneId: this.selectedTimezoneId,
    }
  },
  computed: {
    localSelectedTimezoneOption() {
      return this.availableTimezones.find(f => f.id === this.localSelectedTimezoneId) ?? this.availableTimezones[0]
    },
    localSelectedInterval() {
      if (!this.hasSelection) {
        return this.intervalIntoTimezone(this.selectedInterval, this.localSelectedTimezoneOption.tz)
      }
      const start = DateTime.fromISO(this.selectionStart).setZone(this.localSelectedTimezoneOption.tz)
      const end = DateTime.fromISO(this.selectionEnd).setZone(this.localSelectedTimezoneOption.tz)
      if (start.toMillis() <= end.toMillis()) {
        return Interval.fromDateTimes(start.startOf('day'), end.endOf('day'))
      } else {
        return Interval.fromDateTimes(end.startOf('day'), start.endOf('day'))
      }
    },
    hasSelection() {
      return !!(this.selectionStart && this.selectionEnd)
    },
    canConfirm() {
      const selectedDateChanged = this.hasSelection

      const localSelectedShifts = this.localShiftFiltersEnabled ? this.localSelectedShifts : []

      if (this.localShiftFiltersEnabled && localSelectedShifts.length < 1) {
        return false
      }

      return selectedDateChanged || this.shiftSelectionDidChange || this.selectedTimezoneDidChange || this.shiftFiltersEnabledDidChange
    },
    shiftFiltersEnabledDidChange() {
      return this.localShiftFiltersEnabled !== this.shiftFiltersEnabled
    },
    shiftSelectionDidChange() {
      return !isEqual(this.localSelectedShifts.map(m => m.id).sort(), this.selectedShifts.map(m => m.id).sort())
    },
    selectedTimezoneDidChange() {
      return this.selectedTimezone !== this.localSelectedTimezoneOption
    },
    canGoPrev() {
      return this.firstMondayInView > DateTime.fromISO('2018-07-01T00:00:00.000Z')
    },
    canGoNext() {
      return this.firstMondayInView.plus({ weeks: 5 }) <= DateTime.local()
    },
    selectableDateInterval() {
      if (this.maxSelectableIntervalInDays && this.selectionStart) {
        const intervalStartDate = DateTime.fromISO(this.selectionStart).minus({ days: this.maxSelectableIntervalInDays })
        let endDate = DateTime.fromISO(this.selectionStart).plus({ days: this.maxSelectableIntervalInDays })
        if (this.futureDateDisabled && DateTime.now() < endDate) {
          endDate = DateTime.now().endOf('day')
        }
        return Interval.fromDateTimes(intervalStartDate, endDate)
      }
      return null
    },
  },
  methods: {
    // use this only for date ranges in the calendar
    intervalIntoTimezone(interval, tz) {
      const { start, end } = interval
      const format = 'yyyy-LL-dd'
      const startYMD = start.toFormat(format)
      const endYMD = end.toFormat(format)
      const startParsed = DateTime.fromFormat(startYMD, format, {
        zone: tz,
      })
      const endParsed = DateTime.fromFormat(endYMD, format, {
        zone: tz,
      })
      return Interval.fromDateTimes(startParsed.startOf('day'), endParsed.endOf('day'))
    },
    setSelectedTimezone(timezoneOption) {
      this.localSelectedTimezoneId = timezoneOption.id
      this.firstMondayInView = this.localSelectedInterval.start
        .setZone(timezoneOption.tz)
        .startOf('month')
        .minus({ days: Math.abs(1 - this.selectedInterval.start.startOf('month').weekday) })
    },
    updateSelectionToInterval(interval) {
      if (!interval) {
        return
      }
      if (interval.count('days') > 7) {
        this.firstMondayInView = interval.start.startOf('week')
      }
      this.selectionStart = interval.start.toISO()
      this.selectionEnd = interval.end.toISO()
    },
    handleMouseWheel(e) {
      if (e.deltaY >= 0) {
        this.nextWeek()
      } else {
        this.prevWeek()
      }
    },
    async confirmSelection() {
      if (!this.canConfirm) {
        return
      }
      this.$emit('update-settings', {
        selectedInterval: this.localSelectedInterval,
        selectedShiftplan: this.localSelectedShiftplan,
        selectedShifts: this.localSelectedShifts,
        selectedTimezoneId: this.localSelectedTimezoneId,
        shiftFiltersEnabled: this.localShiftFiltersEnabled,
      })
      this.abortSelection()
      this.$emit('close')
    },
    abortSelection() {
      this.isSelecting = false
      this.selectionStart = null
      this.selectionEnd = null
    },
    prevWeek: throttle(function () {
      if (!this.canGoPrev) {
        return
      }
      this.firstMondayInView = this.firstMondayInView.minus({ weeks: 1 })
    }, 50),
    nextWeek: throttle(function () {
      if (!this.canGoNext) {
        return
      }
      this.firstMondayInView = this.firstMondayInView.plus({ weeks: 1 })
    }, 50),
    getDateFromElements(elems) {
      const found = elems.find(elem => elem?.dataset?.date)
      return found?.dataset?.date ?? null
    },
    handleStart(elems) {
      const date = this.getDateFromElements(elems)
      if (date && this.isDateSelectable(date)) {
        this.isSelecting = true
        this.selectionStart = date
        this.selectionEnd = date
      }
    },
    handleEnd(elems) {
      const date = this.getDateFromElements(elems)
      if (date && this.isDateSelectable(date)) {
        this.isSelecting = false
        this.selectionEnd = date
      }
    },
    handleUpdateEnd(elem) {
      const date = this.getDateFromElements(elem)
      if (date && this.isDateSelectable(date)) {
        this.selectionEnd = date
      }
    },
    isDateSelectable(d) {
      if (!this.futureDateDisabled) {
        return true
      }
      const date = DateTime.fromISO(d)
      // return false for a future date
      if (date > DateTime.now()) {
        return false
      }
      // if its an end date
      if (this.selectableDateInterval && this.isSelecting && this.selectionStart) {
        return this.selectableDateInterval.contains(date)
      }
      return true
    },
    mousedown(e) {
      const pathElements = e.path || (e.composedPath && e.composedPath())

      if (this.isSelecting) {
        this.handleEnd(pathElements)
      } else {
        this.handleStart(pathElements)
      }
    },
    mousemove(e) {
      if (!this.isSelecting) {
        return
      }
      this.handleUpdateEnd(e.path || (e.composedPath && e.composedPath()))
    },
    touchStart(e) {
      this.handleStart(e.path || (e.composedPath && e.composedPath()))
    },
    touchEnd(e) {
      const firstPoint = e.touches[0]
      if (firstPoint) {
        const elems = document.elementsFromPoint(firstPoint.clientX, firstPoint.clientY)
        this.handleEnd(elems)
      }
    },
    touchMove(e) {
      if (!this.isSelecting) {
        return
      }
      const firstPoint = e.touches[0]
      if (firstPoint) {
        const elems = document.elementsFromPoint(firstPoint.clientX, firstPoint.clientY)
        if (elems) {
          this.handleUpdateEnd(elems)
        }
      }
    },
    handleHover() {},
    mouseLeave() {
      if (this.isSelecting) {
        this.selectionEnd = this.selectionStart
      }
    },
    toggleLocalSelectedShift(shift) {
      const found = this.localSelectedShifts.some(s => s.id === shift.id)
      if (found) {
        this.localSelectedShifts = this.localSelectedShifts.filter(s => s.id !== shift.id)
      } else {
        this.localSelectedShifts.push(shift)
      }
    },
    setLocalSelectedShiftplan(shiftplan) {
      this.localSelectedShiftplan = shiftplan
      this.localSelectedShifts = shiftplan.shifts
    },
  },
}
</script>
