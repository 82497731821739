<template>
  <CalendarHeaderStyled>
    <slot />
  </CalendarHeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const CalendarHeaderStyled = styled('div')`
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(8, 40px);
    grid-template-rows: repeat(1, 40px);
  }
`

export default {
  components: {
    CalendarHeaderStyled,
  },
}
</script>
