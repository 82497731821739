var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DayAtom', {
    staticClass: "calendar-item calendar-day",
    class: _vm.classes,
    attrs: {
      "data-date": _vm.day.date.toISO()
    }
  }, [_c('div', {
    staticClass: "local-selected"
  }), _c('div', {
    staticClass: "selected"
  }), _c('div', {
    staticClass: "hover"
  }), _c('div', {
    staticClass: "date"
  }, [_c('span', [_vm._v(_vm._s(_vm.day.date.day))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }