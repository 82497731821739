<template>
  <DropdownSelectorStyled>
    <div>{{ $t('groupBy') }}</div>
    <CustomSingleSelect
      :options="availableSelectors"
      :filter="optionSelected"
      :closeOnSelect="true"
      @selectedFilter="updateSelection"
      :selectedOption="preselectedOption"
      :customOptions="customOptions"
      :customLabels="customLabels"
      :shouldNotResetSelectedOption="true"
      class="select"
    />
  </DropdownSelectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CustomSingleSelect } from '@common/components'

import chroma from 'chroma-js'

const DropdownSelectorStyled = styled('div')`
  margin: 0 0.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  .feed_multiselect {
    width: 100%;
    min-height: 0px;
    .multiselect__content-wrapper {
      min-width: 13rem;
      left: -8rem;
    }
    .multiselect__tags {
      min-height: 0px;
      padding-top: 0.25rem;
    }
    > .caretIcon {
      stroke: ${({ theme }) => theme.colors.primary} !important;
    }
    .multiselect__select {
      height: 30px;
    }
    @media (min-width: 768px) {
      .labelText {
        display: inline-block !important;
      }
      .multiselect__content-wrapper {
        left: 0;
      }
    }
  }
  .select {
    border-radius: 8px;
    background: transparent;
    width: 70%;
    border: 1px solid ${({ theme }) => chroma(theme.colors.primary).alpha(0.5).css()};
  }
`

export default {
  props: {
    availableSelectors: {
      type: Array,
      require: true,
    },
    customOptions: {
      type: String,
      required: false,
    },
    customLabels: {
      type: String,
      required: false,
    },
    preselectionId: {
      type: String,
      required: false,
    },
  },
  components: {
    DropdownSelectorStyled,
    CustomSingleSelect,
  },
  data() {
    return {
      preselectedOption: {},
      optionSelected: null,
    }
  },
  created() {
    if (!this.optionSelected) {
      const preselectionIndex = this.preselectionId ? this.availableSelectors.map(option => option.id).indexOf(this.preselectionId) : 0
      this.optionSelected = this.updateSelection(this.availableSelectors[preselectionIndex])
      this.preselectedOption = this.availableSelectors[preselectionIndex]
    }
  },
  methods: {
    updateSelection(args) {
      this.$emit('selectionChanged', args)
    },
  },
}
</script>
