var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MonthsMolecule', {
    attrs: {
      "viewBox": "0 0 50 350"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.activateMonth.apply(null, arguments);
      }
    }
  }, [_vm._l(_vm.months, function (month) {
    return _c('PolygonStyled', {
      key: month.id,
      attrs: {
        "points": month.points,
        "order": month.order,
        "isLargest": month.isLargest
      }
    });
  }), _vm._l(_vm.months, function (month) {
    return _c('TextStyled', {
      key: month.id + '_text-month',
      attrs: {
        "x": 25,
        "y": month.textY - 10,
        "order": month.order,
        "text-anchor": "middle",
        "height": month.height
      }
    }, [_vm._v(" " + _vm._s(month.date.toFormat('LLL', {
      locale: _vm.locale
    })) + " ")]);
  }), _vm._l(_vm.months, function (month) {
    return _c('TextStyled', {
      key: month.id + '_text-year',
      attrs: {
        "x": 25,
        "y": month.textY + 10,
        "order": month.order,
        "text-anchor": "middle",
        "height": month.height
      }
    }, [_vm._v(" " + _vm._s(month.date.toFormat('yy')) + " ")]);
  }), _vm._l(_vm.months, function (month) {
    return _c('PolygonTransparent', {
      key: month.id + '_transparent',
      attrs: {
        "points": month.points,
        "order": month.order,
        "data-date": month.dateFormatted,
        "isLargest": month.isLargest
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }