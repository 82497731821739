<template>
  <HeaderItemAtom :class="{ 'is-cw': isCW }">
    {{ label }}
  </HeaderItemAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter } from '@styles/mixins'

const HeaderItemAtom = styled('span')`
  ${flexCenter};
  user-select: none;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.white};
  background: ${props => (props.isCW ? props.theme.colors.primary : props.theme.colors.darkGrey)};
  &.is-cw {
    border-right: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : theme.colors.atomic.bunkerLight)};
  }
`

export default {
  components: {
    HeaderItemAtom,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    isCW: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
