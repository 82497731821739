var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CalendarWeeksMolecule', _vm._l(_vm.calendarWeeks, function (cw) {
    return _c('CalendarWeekAtom', {
      key: cw.id,
      attrs: {
        "cw": cw,
        "futureDateDisabled": _vm.futureDateDisabled
      },
      on: {
        "setHighlightedInterval": function setHighlightedInterval($event) {
          return _vm.$emit('setHighlightedInterval', $event);
        },
        "updateSelectionToInterval": function updateSelectionToInterval($event) {
          return _vm.$emit('updateSelectionToInterval', $event);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }