var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetListStyled', [_c('AssetListItemsStyled', {
    ref: "groupedAssetList"
  }, [_c('ButtonWrapperStyled', _vm._l(_vm.selectionButtons, function (button) {
    return _c('SelectButtonStyled', {
      key: button.id,
      attrs: {
        "disabled": button.id === 'select' ? _vm.disableSelectAll : !!_vm.canDeselectAll
      },
      on: {
        "click": function click($event) {
          return _vm.selectDeselectAll(button.id);
        }
      }
    }, [button.id === 'select' ? _c('CheckCircleIcon', {
      attrs: {
        "size": "1.45x"
      }
    }) : _c('MinusCircleIcon', {
      attrs: {
        "size": "1.45x"
      }
    }), _c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(_vm.$tc("actions.".concat(button.label))) + " ")])], 1);
  }), 1), _vm.canDeselectAll ? _c('InfoTabStyled', [_c('InfoIcon', {
    attrs: {
      "size": "1.5x"
    }
  }), _vm._v(" " + _vm._s(_vm.$tc('messages.assetSelector')))], 1) : _vm._e(), _vm._l(_vm.groupedAssetMetaData, function (groupedData) {
    return _c('AssetsSelectorListItem', {
      key: groupedData.id,
      attrs: {
        "groupedData": groupedData,
        "assets": _vm.assets,
        "expandedGroupIds": _vm.expandedGroupIds,
        "shownAssetIdsLocal": _vm.shownAssetIdsLocal,
        "groupId": _vm.groupId
      },
      on: {
        "toggle-asset-shown": _vm.toggleAssetShown,
        "toggle-many-assets-shown": _vm.toggleManyAssetsShown,
        "toggle-group-expanded": _vm.toggleGroupExpanded
      }
    });
  })], 2), _c('ButtonsSection', [_c('ButtonStyleless', {
    attrs: {
      "disabled": !_vm.canApply
    },
    on: {
      "click": _vm.setHiddenAssetsToStore
    }
  }, [_c('CheckIcon'), _vm._v(" " + _vm._s(_vm.$t('actions.apply')) + " ")], 1), _c('ButtonStyleless', {
    attrs: {
      "disabled": !_vm.canCancel
    },
    on: {
      "click": _vm.resetShownAssets
    }
  }, [_c('XIcon'), _vm._v(" " + _vm._s(_vm.$t('actions.cancel')) + " ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }