var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimepickerShiftFiltersMolecule', [_c('div', {
    staticClass: "toggle"
  }, [_c('OnOffToggle', {
    attrs: {
      "value": _vm.shiftFiltersEnabled,
      "label": _vm.$t('dashboard.shiftsView')
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.$emit('toggle-shift-filters-enabled');
      }
    }
  })], 1), _vm.shiftFiltersEnabled ? _c('transition', {
    attrs: {
      "name": "fade-in-down"
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "select-wrapper"
  }, [_c('span', {
    staticClass: "select-wrapper text"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.shiftSystem')))]), _c('Multiselect', {
    staticClass: "shift-multi-select",
    attrs: {
      "value": _vm.selectedShiftplan,
      "multiple": false,
      "options": _vm.availableShiftplans,
      "showLabels": false,
      "maxHeight": 210,
      "placeholder": _vm.$t('shifts.selectPlaceholder'),
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('set-selected-shiftplan', $event);
      }
    }
  })], 1), !!_vm.selectedShiftplan ? _c('ShiftAtom', {
    attrs: {
      "selectedShiftplan": _vm.selectedShiftplan,
      "selectedShifts": _vm.selectedShifts
    },
    on: {
      "toggle-shift-selected": function toggleShiftSelected($event) {
        return _vm.$emit('toggle-shift-selected', $event);
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }