<template>
  <CalendarWeeksMolecule>
    <CalendarWeekAtom
      v-for="cw in calendarWeeks"
      :key="cw.id"
      :cw="cw"
      :futureDateDisabled="futureDateDisabled"
      @setHighlightedInterval="$emit('setHighlightedInterval', $event)"
      @updateSelectionToInterval="$emit('updateSelectionToInterval', $event)"
    />
  </CalendarWeeksMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import CalendarWeekAtom from '@/components/Atomic/Atoms/Misc/Timepicker/CalendarWeekAtom.vue'

const CalendarWeeksMolecule = styled('div')`
  grid-column-start: col1-start;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 8;
  border-right: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : theme.colors.atomic.bunkerLight)};
  display: grid;

  div {
    ${flexCenter}
  }
`

export default {
  props: {
    calendarWeeks: {
      type: Array,
      required: true,
    },
    localSelectedTimezoneOption: {
      type: Object,
      required: true,
    },
    futureDateDisabled: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CalendarWeeksMolecule,
    CalendarWeekAtom,
  },
}
</script>
