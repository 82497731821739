var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimepickerTimezoneMolecule', [_c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t('timezone.timezone')))]), _c('Multiselect', {
    staticClass: "shift-multi-select",
    attrs: {
      "value": _vm.selectedTimezoneOption,
      "multiple": false,
      "open-direction": "above",
      "max-height": 200,
      "options": _vm.availableTimezonesMapped,
      "showLabels": false,
      "placeholder": _vm.$t('timezone.selectTimezonePlaceholder'),
      "trackBy": "id",
      "label": "label"
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('set-selected-timezone', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }