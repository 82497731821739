<template>
  <DaysMolecule
    @wheel.prevent.stop="$emit('handleMouseWheel', $event)"
    @mousedown.prevent.stop="$emit('mousedown', $event)"
    @mouseup.prevent.stop="$emit('mouseup', $event)"
    @mousemove.prevent.stop="$emit('mousemove', $event)"
    @mouseleave="$emit('mouseLeave', $event)"
    @touchstart.prevent.stop="$emit('touchStart', $event)"
    @touchend.prevent.stop="$emit('touchEnd', $event)"
    @touchcancel.prevent.stop="$emit('touchEnd', $event)"
    @mouseenter="$emit('handleHover', $event)"
    @touchmove="$emit('touchMove', $event)"
  >
    <DayAtom
      v-for="day in days"
      :key="day.id"
      :day="day"
      :isSelecting="isSelecting"
      :hasSelection="hasSelection"
      :highlightedInterval="highlightedInterval"
      :selectedInterval="selectedInterval"
      :localSelectedInterval="localSelectedInterval"
    />
  </DaysMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import DayAtom from '@/components/Atomic/Atoms/Misc/Timepicker/DayAtom.vue'

const DaysMolecule = styled('div')`
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 2;
  grid-row-end: 8;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(7, 40px);
    grid-template-rows: repeat(6, 40px);
  }
`

export default {
  props: {
    days: {
      type: Array,
      required: true,
    },
    isSelecting: {
      type: Boolean,
      required: true,
    },
    hasSelection: {
      type: Boolean,
      required: true,
    },
    highlightedInterval: {
      type: Object,
      required: false,
    },
    selectedInterval: {
      type: Object,
      required: false,
    },
    localSelectedInterval: {
      type: Object,
      required: false,
    },
    localSelectedTimezoneOption: {
      type: Object,
      required: true,
    },
  },
  components: {
    DaysMolecule,
    DayAtom,
  },
}
</script>
