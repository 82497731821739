var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimepickerHeaderMolecule', {
    class: {
      active: _vm.isExpanded
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle-is-exanded');
      }
    }
  }, [_c('FeatherIconAtom', {
    attrs: {
      "iconName": 'ClockIcon'
    }
  }), _c('SelectedTimeframeExplainedAtom'), _c('Badge', {
    staticClass: "badge-selected-duration",
    attrs: {
      "label": _vm.durationShort
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }