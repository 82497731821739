var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CalendarMolecule', [_c('HeaderItemsMolecule', [_c('HeaderItemAtom', {
    attrs: {
      "label": _vm.$t('dashboard.time.calendarWeek'),
      "isCW": true
    }
  }), _vm._l(_vm.weekdayNames, function (name, i) {
    return _c('HeaderItemAtom', {
      key: i,
      attrs: {
        "label": name
      }
    });
  })], 2), _c('CalendarWeeksMolecule', {
    attrs: {
      "calendarWeeks": _vm.calendarWeeks,
      "localSelectedTimezoneOption": _vm.localSelectedTimezoneOption,
      "futureDateDisabled": _vm.futureDateDisabled
    },
    on: {
      "setHighlightedInterval": function setHighlightedInterval($event) {
        return _vm.$emit('setHighlightedInterval', $event);
      },
      "updateSelectionToInterval": function updateSelectionToInterval($event) {
        return _vm.$emit('updateSelectionToInterval', $event);
      }
    }
  }), _c('DaysMolecule', {
    attrs: {
      "days": _vm.days,
      "isSelecting": _vm.isSelecting,
      "hasSelection": _vm.hasSelection,
      "highlightedInterval": _vm.highlightedInterval,
      "selectedInterval": _vm.selectedInterval,
      "localSelectedInterval": _vm.localSelectedInterval,
      "localSelectedTimezoneOption": _vm.localSelectedTimezoneOption
    },
    on: {
      "handleMouseWheel": function handleMouseWheel($event) {
        return _vm.$emit('handleMouseWheel', $event);
      },
      "mousedown": function mousedown($event) {
        return _vm.$emit('mousedown', $event);
      },
      "mousemove": function mousemove($event) {
        return _vm.$emit('mousemove', $event);
      },
      "mouseLeave": function mouseLeave($event) {
        return _vm.$emit('mouseLeave', $event);
      },
      "touchStart": function touchStart($event) {
        return _vm.$emit('touchStart', $event);
      },
      "touchEnd": function touchEnd($event) {
        return _vm.$emit('touchEnd', $event);
      },
      "handleHover": function handleHover($event) {
        return _vm.$emit('handleHover', $event);
      },
      "touchMove": function touchMove($event) {
        return _vm.$emit('touchMove', $event);
      }
    }
  }), _c('MonthsMolecule', {
    attrs: {
      "firstMondayInView": _vm.firstMondayInView,
      "localSelectedTimezoneOption": _vm.localSelectedTimezoneOption,
      "futureDateDisabled": _vm.futureDateDisabled
    },
    on: {
      "setHighlightedInterval": function setHighlightedInterval($event) {
        return _vm.$emit('setHighlightedInterval', $event);
      },
      "updateSelectionToInterval": function updateSelectionToInterval($event) {
        return _vm.$emit('updateSelectionToInterval', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }