var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimepickerContentMolecule', [_c('CalendarMolecule', {
    attrs: {
      "firstMondayInView": _vm.firstMondayInView,
      "selectionStart": _vm.selectionStart,
      "selectionEnd": _vm.selectionEnd,
      "isSelecting": _vm.isSelecting,
      "hasSelection": _vm.hasSelection,
      "highlightedInterval": _vm.highlightedInterval,
      "localSelectedInterval": _vm.localSelectedInterval,
      "localSelectedTimezoneOption": _vm.localSelectedTimezoneOption,
      "localShiftFiltersEnabled": _vm.localShiftFiltersEnabled,
      "localSelectedShifts": _vm.localSelectedShifts,
      "selectedInterval": _vm.selectedInterval,
      "selectedShifts": _vm.selectedShifts,
      "selectedTimezone": _vm.selectedTimezone,
      "selectableDateInterval": _vm.selectableDateInterval,
      "hasShiftFilter": _vm.hasShiftFilter,
      "canConfirm": _vm.canConfirm,
      "canGoPrev": _vm.canGoPrev,
      "canGoNext": _vm.canGoNext,
      "futureDateDisabled": _vm.futureDateDisabled
    },
    on: {
      "handleMouseWheel": _vm.handleMouseWheel,
      "mousedown": _vm.mousedown,
      "mousemove": _vm.mousemove,
      "mouseLeave": _vm.mouseLeave,
      "touchStart": _vm.touchStart,
      "touchEnd": _vm.touchEnd,
      "handleHover": _vm.handleHover,
      "touchMove": _vm.touchMove,
      "setHighlightedInterval": function setHighlightedInterval($event) {
        _vm.highlightedInterval = $event;
      },
      "updateSelectionToInterval": _vm.updateSelectionToInterval
    }
  }), _c('div', {
    staticClass: "extra-options"
  }, [_c('TimepickerTimezoneMolecule', {
    attrs: {
      "localSelectedTimezoneOption": _vm.localSelectedTimezoneOption,
      "availableTimezones": _vm.availableTimezones
    },
    on: {
      "set-selected-timezone": _vm.setSelectedTimezone
    }
  }), _vm.hasShiftFilter ? _c('TimepickerShiftFiltersMolecule', {
    attrs: {
      "shiftFiltersEnabled": _vm.localShiftFiltersEnabled,
      "availableShiftplans": _vm.availableShiftplans,
      "selectedShiftplan": _vm.localSelectedShiftplan,
      "selectedShifts": _vm.localSelectedShifts
    },
    on: {
      "toggle-shift-filters-enabled": function toggleShiftFiltersEnabled($event) {
        _vm.localShiftFiltersEnabled = !_vm.localShiftFiltersEnabled;
      },
      "set-selected-shiftplan": _vm.setLocalSelectedShiftplan,
      "toggle-shift-selected": _vm.toggleLocalSelectedShift
    }
  }) : _vm._e()], 1), _c('TimepickerFooterMolecule', {
    attrs: {
      "canConfirm": _vm.canConfirm,
      "canGoPrev": _vm.canGoPrev,
      "canGoNext": _vm.canGoNext
    },
    on: {
      "confirmSelection": _vm.confirmSelection,
      "abortSelection": _vm.abortSelection,
      "prevWeek": _vm.prevWeek,
      "nextWeek": _vm.nextWeek
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }