var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CalendarWeekAtom', {
    staticClass: "calendar-item calendar-week",
    class: _vm.classes,
    attrs: {
      "isCurrentCW": _vm.isCurrentCW
    },
    on: {
      "click": _vm.activateCalendarWeek,
      "mouseenter": _vm.setHighlightedInterval,
      "mouseleave": _vm.unsetHighlightedInterval
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.cw.date.weekNumber))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }