var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SelectedTimeframeExplainedAtom', [_c('span', [_vm._v(_vm._s(_vm.selectedTimeframeDateInterpreted))]), _vm.shouldShowSelectedTime ? _c('div', {
    staticClass: "shift-dates"
  }, [_vm._v(_vm._s(_vm.shownSelectedTime))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }