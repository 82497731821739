<template>
  <TimepickerMolecule v-away="away">
    <TimepickerToggleMolecule :selectedInterval="selectedInterval" :isExpanded="isExpanded" @toggle-is-exanded="toggleIsExpanded" />
    <transition tag="div" name="fade-in-down">
      <TimepickerContentMolecule
        class="foo"
        v-if="isExpanded"
        :selectedInterval="selectedInterval"
        :availableTimezones="availableTimezones"
        :selectedTimezone="selectedTimezone"
        :selectedTimezoneId="selectedTimezoneId"
        :hasShiftFilter="hasShiftFilter"
        :shiftFiltersEnabled="shiftFiltersEnabled"
        :availableShiftplans="availableShiftplans"
        :selectedShiftplan="selectedShiftplan"
        :selectedShifts="selectedShifts"
        :futureDateDisabled="futureDateDisabled"
        :maxSelectableIntervalInDays="maxSelectableIntervalInDays"
        @update-settings="$emit('update-settings', $event)"
        @close="away"
      />
    </transition>
  </TimepickerMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { directive as onClickaway } from 'vue-clickaway'

import TimepickerToggleMolecule from './TimepickerMolecule/TimepickerToggleMolecule.vue'
import TimepickerContentMolecule from './TimepickerMolecule/TimepickerContentMolecule.vue'

const TimepickerMolecule = styled('div')`
  position: relative;
  width: 360px;
  max-width: 50%;
`

export default {
  props: {
    selectedInterval: {
      type: Object,
      required: true,
    },
    selectedShifts: {
      type: Array,
      required: true,
    },
    selectedTimezone: {
      type: String,
      required: true,
    },
    selectedTimezoneId: {
      type: String,
      required: true,
    },
    hasShiftFilter: {
      type: Boolean,
      required: true,
    },
    shiftFiltersEnabled: {
      type: Boolean,
      required: true,
    },
    availableShiftplans: {
      type: Array,
      required: true,
    },
    selectedShiftplan: {
      type: Object,
    },
    availableTimezones: {
      type: Array,
      required: true,
    },
    futureDateDisabled: {
      type: Boolean,
      required: true,
    },
    maxSelectableIntervalInDays: {
      type: Number,
    },
  },
  components: {
    TimepickerMolecule,
    TimepickerToggleMolecule,
    TimepickerContentMolecule,
  },
  data() {
    return {
      isExpanded: false,
      contentOffsetY: 0,
    }
  },
  directives: {
    away: onClickaway,
  },
  methods: {
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded
      this.$root.$emit('calendarActive', this.isExpanded)
      if (this.$refs.toggle) {
        const bounds = this.$refs.toggle.getBoundingClientRect()
        this.contentOffsetY = bounds.y + bounds.height
      }
    },
    away(e) {
      if (e && e.srcElement.name === 'popoverButton') {
        this.isExpanded = true
      } else {
        this.isExpanded = false
        this.$root.$emit('calendarActive', this.isExpanded)
      }
    },
  },
}
</script>
