<template>
  <CalendarWeekAtom
    :isCurrentCW="isCurrentCW"
    :class="classes"
    class="calendar-item calendar-week"
    @click="activateCalendarWeek"
    @mouseenter="setHighlightedInterval"
    @mouseleave="unsetHighlightedInterval"
  >
    <span>{{ cw.date.weekNumber }}</span>
  </CalendarWeekAtom>
</template>

<script>
import { DateTime, Interval } from 'luxon'

import { styled } from '@egoist/vue-emotion'

const CalendarWeekAtom = styled('button')`
  user-select: none;
  outline: none;
  border: none;
  background: none;
  color: ${p => p.theme.colors.muted};

  &.selectable {
    cursor: pointer;
    color: ${p => (p.isActive ? p.theme.colors.primary : p.theme.isDark ? p.theme.colors.white : p.theme.colors.navFontNormal)};

    &:hover {
      color: ${p => p.theme.colors.primaryLighter};
    }
  }
  span {
    ${props => (props.isCurrentCW ? 'border-bottom: 1px solid ' + props.theme.colors.primary : '')}
  }
`

export default {
  components: {
    CalendarWeekAtom,
  },
  props: {
    cw: {
      type: Object,
      required: true,
    },
    futureDateDisabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    interval() {
      const start = this.cw.date.startOf('week')
      if (this.futureDateDisabled && start > DateTime.now()) {
        return null
      }
      let end = this.cw.date.endOf('week')
      if (this.futureDateDisabled && end > DateTime.now()) {
        end = DateTime.now().endOf('day')
      }
      return Interval.fromDateTimes(start, end)
    },
    isCurrentCW() {
      return this.cw.date.startOf('week').hasSame(DateTime.local().startOf('week'), 'day')
    },
    classes() {
      return {
        selectable: this.cw.isSelectable,
      }
    },
  },
  methods: {
    activateCalendarWeek() {
      this.$emit('updateSelectionToInterval', this.interval)
    },
    setHighlightedInterval() {
      this.$emit('setHighlightedInterval', this.interval)
    },
    unsetHighlightedInterval() {
      this.$emit('setHighlightedInterval', null)
    },
  },
}
</script>
