<template>
  <TimepickerFooterMolecule>
    <ButtonStyleless :disabled="!canConfirm" name="confirm_selection" @click.stop="$emit('confirmSelection')">
      <CheckIcon />
    </ButtonStyleless>
    <ButtonStyleless :disabled="!canConfirm" name="abort_selection" @click.stop="$emit('abortSelection')">
      <XIcon />
    </ButtonStyleless>
    <ButtonStyleless :disabled="!canGoPrev" name="previous_week" @click.stop="$emit('prevWeek')">
      <ChevronUpIcon />
    </ButtonStyleless>
    <ButtonStyleless :disabled="!canGoNext" name="next_week" @click.stop="$emit('nextWeek')">
      <ChevronDownIcon />
    </ButtonStyleless>
  </TimepickerFooterMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CheckIcon, XIcon, ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons'

import { buttonReset } from '@styles/mixins'

import permissionsMixin from '@/mixins/permissions'

const ButtonStyleless = styled('button')`
  ${buttonReset}
  &:disabled {
    color: ${props => props.theme.colors.muted};
    opacity: 0.5;
  }
`

const TimepickerFooterMolecule = styled('div')`
  position: sticky;
  z-index: 110;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 40px;
  align-items: center;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : theme.colors.solidBG)};
  box-shadow: ${({ theme }) => theme.colors.widgetShadowEnforced};
  @media (min-width: 768px) {
    grid-template-columns: repeat(9, 40px);
  }
  button[name='confirm_selection'] {
    display: grid;
    grid-column: 8;
    grid-row: 1;
  }
  button[name='abort_selection'] {
    display: grid;
    grid-column: 9;
    grid-row: 1;
  }
  button[name='previous_week'] {
    display: grid;
    grid-column: 1;
    grid-row: 1;
  }
  button[name='next_week'] {
    display: grid;
    grid-column: 2;
    grid-row: 1;
  }
`

export default {
  mixins: [permissionsMixin],
  props: {
    canConfirm: {
      type: Boolean,
      required: true,
    },
    canGoPrev: {
      type: Boolean,
      required: true,
    },
    canGoNext: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TimepickerFooterMolecule,
    CheckIcon,
    XIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    ButtonStyleless,
  },
}
</script>
