<template>
  <DayAtom class="calendar-item calendar-day" :data-date="day.date.toISO()" :class="classes">
    <div class="local-selected" />
    <div class="selected" />
    <div class="hover" />
    <div class="date">
      <span>{{ day.date.day }}</span>
    </div>
  </DayAtom>
</template>

<script>
import { DateTime } from 'luxon'
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexCenter } from '@styles/mixins'

const DayAtom = styled('button')`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px 0;
  border: none;
  outline: none;
  background: none;
  .local-selected,
  .selected,
  .hover,
  .date {
    position: absolute;
    width: 100%;
    height: calc(100% - 8px);
    top: 4px;
    left: 0;
    opacity: 0;
  }
  .local-selected {
    background: ${p => p.theme.colors.primary};
  }
  &.local-selected-start .local-selected {
    left: 4px;
    width: calc(100% - 4px);
  }
  &.local-selected-end .local-selected {
    width: calc(100% - 4px);
  }
  &.local-selected-start.local-selected-end .local-selected {
    width: calc(100% - 8px);
  }
  &.is-selecting .localselected,
  &.has-selection .localselected {
    background: ${p => chroma(p.theme.colors.primaryDarker).alpha(0.5).css()};
  }
  .selected {
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.primary};
  }
  &.selected-start .selected {
    left: 4px;
    width: calc(100% - 4px);
  }
  &.selected-end .selected {
    width: calc(100% - 4px);
  }
  &.selected-start.selected-end .selected {
    width: calc(100% - 8px);
  }
  .hover {
    color: ${p => p.theme.colors.white};
    background: ${p => chroma(p.theme.colors.primaryLighter).alpha(0.75).css()};
    top: 4px;
    height: calc(100% - 8px);
  }
  &.highlighted-start .hover {
    left: 4px;
    width: calc(100% - 4px);
  }
  &.highlighted-end .hover {
    width: calc(100% - 4px);
  }
  &.highlighted-start.highlighted-end .hover {
    width: calc(100% - 8px);
  }
  .date {
    ${flexCenter}
    color: ${p => p.theme.colors.muted};
    opacity: 1;
  }

  &.is-today .date span {
    border-bottom: 1px solid ${p => p.theme.colors.primary};
  }

  &.selectable {
    cursor: pointer;
    .date {
      color: ${p => p.theme.colors.navFontNormal};
    }

    &:hover {
      .hover {
        opacity: 1;
        border-radius: 50%;
        left: 4px;
        width: calc(100% - 8px);
      }
    }
  }

  &.local-selected .date {
    color: ${p => p.theme.colors.white};
  }

  &.selected .date {
    color: ${p => p.theme.colors.white};
  }

  &.local-selected .local-selected {
    opacity: 1;
  }
  &.local-selected-start .local-selected {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  &.local-selected-end .local-selected {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &.selected .selected {
    opacity: 1;
  }
  &.has-selection.selected .selected {
    opacity: 0.5;
  }
  &.selected-start .selected {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  &.selected-end .selected {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &.highlighted .hover {
    opacity: 1;
  }
  &.highlighted-start .hover {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  &.highlighted-end .hover {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
`
export default {
  inject: ['theme'],
  components: {
    DayAtom,
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    isSelecting: {
      type: Boolean,
      required: true,
    },
    hasSelection: {
      type: Boolean,
      required: true,
    },
    highlightedInterval: {
      type: Object,
      required: false,
    },
    selectedInterval: {
      type: Object,
      required: false,
    },
    localSelectedInterval: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isToday() {
      return this.day.date.hasSame(DateTime.local(), 'day')
    },
    isSelected() {
      return this.selectedInterval && this.selectedInterval.contains(this.day.date)
    },
    isSelectedStart() {
      return this.isSelected && this.selectedInterval.start.hasSame(this.day.date, 'day')
    },
    isSelectedEnd() {
      const e = this.isSelected && this.selectedInterval.end.hasSame(this.day.date, 'day')
      return e
    },
    isHighlighted() {
      return this.highlightedInterval && this.highlightedInterval.contains(this.day.date)
    },
    isHighlightedStart() {
      return this.isHighlighted && this.highlightedInterval.start.hasSame(this.day.date, 'day')
    },
    isHighlightedEnd() {
      const e = this.isHighlighted && this.highlightedInterval.end.hasSame(this.day.date, 'day')
      return e
    },
    isLocalSelected() {
      return (
        this.localSelectedInterval &&
        (this.localSelectedInterval.contains(this.day.date) ||
          this.localSelectedInterval.start?.hasSame(this.day.date, 'day') ||
          this.localSelectedInterval.end?.hasSame(this.day.date, 'day'))
      )
    },
    isLocalSelectedStart() {
      return this.isLocalSelected && this.localSelectedInterval.start.hasSame(this.day.date, 'day')
    },
    isLocalSelectedEnd() {
      const e = this.isLocalSelected && this.localSelectedInterval.end.hasSame(this.day.date, 'day')
      return e
    },
    classes() {
      return {
        'is-selecting': this.isSelecting,
        'is-today': this.isToday,
        'has-selection': this.hasSelection,
        selectable: this.day.isSelectable,
        selected: this.isSelected,
        'selected-start': this.isSelectedStart,
        'selected-end': this.isSelectedEnd,
        'local-selected': this.isLocalSelected,
        'local-selected-start': this.isLocalSelectedStart,
        'local-selected-end': this.isLocalSelectedEnd,
        highlighted: this.isHighlighted,
        'highlighted-start': this.isHighlightedStart,
        'highlighted-end': this.isHighlightedEnd,
      }
    },
  },
}
</script>

<style></style>
