var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimepickerMolecule', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.away,
      expression: "away"
    }]
  }, [_c('TimepickerToggleMolecule', {
    attrs: {
      "selectedInterval": _vm.selectedInterval,
      "isExpanded": _vm.isExpanded
    },
    on: {
      "toggle-is-exanded": _vm.toggleIsExpanded
    }
  }), _c('transition', {
    attrs: {
      "tag": "div",
      "name": "fade-in-down"
    }
  }, [_vm.isExpanded ? _c('TimepickerContentMolecule', {
    staticClass: "foo",
    attrs: {
      "selectedInterval": _vm.selectedInterval,
      "availableTimezones": _vm.availableTimezones,
      "selectedTimezone": _vm.selectedTimezone,
      "selectedTimezoneId": _vm.selectedTimezoneId,
      "hasShiftFilter": _vm.hasShiftFilter,
      "shiftFiltersEnabled": _vm.shiftFiltersEnabled,
      "availableShiftplans": _vm.availableShiftplans,
      "selectedShiftplan": _vm.selectedShiftplan,
      "selectedShifts": _vm.selectedShifts,
      "futureDateDisabled": _vm.futureDateDisabled,
      "maxSelectableIntervalInDays": _vm.maxSelectableIntervalInDays
    },
    on: {
      "update-settings": function updateSettings($event) {
        return _vm.$emit('update-settings', $event);
      },
      "close": _vm.away
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }