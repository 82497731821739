var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DropdownSelectorStyled', [_c('div', [_vm._v(_vm._s(_vm.$t('groupBy')))]), _c('CustomSingleSelect', {
    staticClass: "select",
    attrs: {
      "options": _vm.availableSelectors,
      "filter": _vm.optionSelected,
      "closeOnSelect": true,
      "selectedOption": _vm.preselectedOption,
      "customOptions": _vm.customOptions,
      "customLabels": _vm.customLabels,
      "shouldNotResetSelectedOption": true
    },
    on: {
      "selectedFilter": _vm.updateSelection
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }