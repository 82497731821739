<template>
  <SelectedTimeframeExplainedAtom>
    <span>{{ selectedTimeframeDateInterpreted }}</span>
    <div class="shift-dates" v-if="shouldShowSelectedTime">{{ shownSelectedTime }}</div>
  </SelectedTimeframeExplainedAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { interpreteTimeframe } from '@common/utils'
import { TimeframeMixin } from '@common/mixins'
import { DateTime } from 'luxon'

import { getScheduleOccurencesInInterval } from '@common/utils'

const SelectedTimeframeExplainedAtom = styled('div')`
  .shift-dates {
    font-size: 12px;
  }
`

export default {
  mixins: [TimeframeMixin],
  inject: ['uiSettings'],
  components: {
    SelectedTimeframeExplainedAtom,
  },
  computed: {
    shouldShowSelectedTime() {
      return this.selectedShifts.length > 0
    },
    minStartMaxEnd() {
      const { minStart, maxEnd } = this.selectedShifts.reduce(
        (acc, shift) => {
          const occurrences = getScheduleOccurencesInInterval(shift, this.selectedInterval)
          return occurrences.reduce((innerAcc, occurrence) => {
            const start = occurrence.date
            const end = start.plus(occurrence.duration)
            if (innerAcc.minStart && innerAcc.maxEnd) {
              return {
                minStart: start.toMillis() < innerAcc.minStart.toMillis() ? start : innerAcc.minStart,
                maxEnd: end.toMillis() > innerAcc.maxEnd.toMillis() ? end : innerAcc.maxEnd,
              }
            }
            return {
              minStart: start,
              maxEnd: end,
            }
          }, acc)
        },
        {
          minStart: null,
          maxEnd: null,
        },
      )

      return {
        minStart,
        maxEnd,
      }
    },
    selectedTimeframeDateInterpreted() {
      const locale = this.uiSettings?.dates
      if (this.selectedInterval) {
        const intlToday = this.$t('dashboard.time.today')
        return interpreteTimeframe({ interval: this.selectedInterval }, locale.toLowerCase().replace('_', '-'), intlToday)
      }
      return ''
    },
    shownSelectedTime() {
      const { minStart, maxEnd } = this.minStartMaxEnd

      return `${this.formatDateToTime(minStart)} - ${this.formatDateToTime(maxEnd)}`
    },
    locale() {
      return (this.uiSettings?.dates ?? '').toLowerCase().replace('_', '-')
    },
  },
  methods: {
    formatDateToTime(date) {
      return date.setZone(this.selectedTimezone).setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE)
    },
  },
}
</script>
