<template>
  <ShiftAtom :ref="'wrapper'" :isScroll="isScroll">
    <ShiftPlanHeader>
      <div class="shift-plan-header name">{{ $t('dashboard.shiftName') }}</div>
      <div class="shift-plan-header times">{{ $t('dashboard.shiftTimes') }}</div>
    </ShiftPlanHeader>
    <ShiftPlanRow :key="shift.id" v-for="shift in shifts">
      <div class="row-name" :class="{ tooltip: shift.hasTooltip }" v-tooltip="tooltip(shift, 'click')">{{ shift.name }}</div>
      <div class="row-content">
        <Shift @click="$emit('toggle-shift-selected', shift)">
          <span class="time">{{ shift.start | timeSimple(locale) }} - {{ shift.end | timeSimple(locale) }}</span>
          <ShiftMarker :class="isShiftChecked(shift) ? 'marker-active' : 'marker-inactive'">
            <transition name="fade">
              <CheckIcon class="icon" v-show="isShiftChecked(shift)" />
            </transition>
          </ShiftMarker>
        </Shift>
      </div>
    </ShiftPlanRow>
  </ShiftAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CheckIcon } from 'vue-feather-icons'
import { timeSimple } from '@/utils/filters/time'
import { DateTime } from 'luxon'
import { getRScheduleBySchedule } from '@common/utils'
import { VTooltip } from 'v-tooltip'
import get from 'lodash/get'

const ShiftAtom = styled('div')`
  margin-top: 25px;
  color: ${p => p.theme.colors.primary};
  font-size: 12px;

  ${({ isScroll }) => isScroll && 'padding-right: 15px'};
`

const ShiftPlanHeader = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.colors.steelGrey};
  font-size: 10px;
  margin-bottom: 15px;

  & > .name {
    width: 100px;
  }

  & > .times {
    margin-left: 5px;
    flex-grow: 1;
  }
`

const ShiftPlanRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > .row-name {
    width: 100px;
    font-size: 13px;
    &.tooltip {
      cursor: pointer;
      user-select: none;
      z-index: 100;
    }
  }

  & > .row-content {
    margin-left: 5px;
    flex-grow: 1;
  }
`

const Shift = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => (theme.isDark ? theme.colors.darkBlackBlue : theme.colors.mainBG)};
  border-radius: 10px;
  height: 40px;
  padding: 0 15px;
  cursor: pointer;

  & > .marker-inactive {
    border-style: solid;
    border-color: #2e2e2e;
    border-width: 1px;

    transition: border-width 0.1s linear;
  }

  & > .marker-active {
    background: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    & > .marker-inactive {
      border-width: 3px;
    }
  }

  & > .time {
    font-size: 13px;
  }
`

const ShiftMarker = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  box-sizing: border-box;

  & > .icon {
    width: 12px;
    height: 12px;
    color: ${({ theme }) => theme.colors.mainBG};
  }
`

export default {
  inject: ['uiSettings', 'permissions'],
  props: {
    selectedShiftplan: {
      type: Object,
      required: true,
    },
    selectedShifts: {
      type: Array,
      required: true,
    },
  },
  components: {
    ShiftAtom,
    ShiftPlanHeader,
    ShiftPlanRow,
    Shift,
    ShiftMarker,
    CheckIcon,
  },
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      scrollHeight: 0,
      clientHeight: 0,
    }
  },
  filters: {
    timeSimple,
  },
  computed: {
    shifts() {
      const shifts = this.selectedShiftplan?.shifts || []
      const mapped = shifts.map(schedule => {
        // TODO: find a solution for more than 1 recurrence
        if (schedule?.useTimezoneInRSchedule) {
          const rSchedule = getRScheduleBySchedule(schedule)
          const firstOccurrence = rSchedule.occurrences({ take: 1 }).next()?.value
          return {
            ...schedule,
            start: firstOccurrence.date,
            end: firstOccurrence.date.plus(firstOccurrence.duration),
            hasTooltip: true,
          }
        } else {
          const start = DateTime.fromISO(schedule.recurrences[0].start)
          const end = start.plus(schedule.recurrences[0].duration)

          return {
            ...schedule,
            start,
            end,
          }
        }
      })
      const sorted = mapped.sort((a, b) => {
        const aTime = a.start.hour + a.start.minute + a.start.second + a.start.millisecond
        const bTime = b.start.hour + b.start.minute + b.start.second + b.start.millisecond
        return aTime - bTime
      })
      return sorted
    },
    locale() {
      return get(this.uiSettings, 'dates', 'DE_DE').toLowerCase().replace('_', '-')
    },
    isScroll() {
      return this.scrollHeight > this.clientHeight
    },
  },
  methods: {
    tooltip(shift, triggers) {
      return {
        content: `
          ${shift.start.zoneName}: ${shift.start.toLocaleString(DateTime.TIME_SIMPLE)} - ${shift.end.toLocaleString(
          DateTime.TIME_SIMPLE,
        )}<br />
          Browser: ${shift.start.setZone('local').toLocaleString(DateTime.TIME_SIMPLE)} - ${shift.end
          .setZone('local')
          .toLocaleString(DateTime.TIME_SIMPLE)}<br />
          UTC: ${shift.start.setZone('UTC').toLocaleString(DateTime.TIME_SIMPLE)} - ${shift.end
          .setZone('UTC')
          .toLocaleString(DateTime.TIME_SIMPLE)}
        `,
        placement: 'top',
        boundariesElement: '#app',
        classes: 'shiftpicker-tooltip',
        trigger: shift.hasTooltip ? triggers : '',
      }
    },
    isShiftChecked(shiftTime) {
      return this.selectedShifts.some(s => s.id === shiftTime.id)
    },
    setHeightProps() {
      const { clientHeight, scrollHeight } = this.$refs.wrapper

      if (clientHeight !== this.clientHeight) {
        this.clientHeight = clientHeight
      }

      if (scrollHeight !== this.scrollHeight) {
        this.scrollHeight = scrollHeight
      }
    },
  },
  mounted() {
    this.setHeightProps()
  },
  updated() {
    this.setHeightProps()
  },
}
</script>
