<template>
  <TimepickerShiftFiltersMolecule>
    <div class="toggle">
      <OnOffToggle :value="shiftFiltersEnabled" @toggle="$emit('toggle-shift-filters-enabled')" :label="$t('dashboard.shiftsView')" />
    </div>
    <transition name="fade-in-down" v-if="shiftFiltersEnabled">
      <div class="main">
        <div class="select-wrapper">
          <span class="select-wrapper text">{{ $t('dashboard.shiftSystem') }}</span>
          <Multiselect
            :value="selectedShiftplan"
            :multiple="false"
            :options="availableShiftplans"
            :showLabels="false"
            :maxHeight="210"
            :placeholder="$t('shifts.selectPlaceholder')"
            trackBy="id"
            label="name"
            @select="$emit('set-selected-shiftplan', $event)"
            class="shift-multi-select"
          />
        </div>
        <ShiftAtom
          v-if="!!selectedShiftplan"
          :selectedShiftplan="selectedShiftplan"
          :selectedShifts="selectedShifts"
          @toggle-shift-selected="$emit('toggle-shift-selected', $event)"
        />
      </div>
    </transition>
  </TimepickerShiftFiltersMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'

import { OnOffToggle } from '@common/components'
import ShiftAtom from '@/components/Atomic/Atoms/Misc/Timepicker/ShiftAtom.vue'

const TimepickerShiftFiltersMolecule = styled('div')`
  font-size: 13px;
  background: ${p => p.theme.colors.solidBG};
  padding: 0.5rem 0;
  .toggle {
    padding: 0.5rem 1rem;
  }

  & > .main {
    padding: 0.5rem;
    min-height: 250px;
    background: ${({ theme }) => (theme.isDark ? `${theme.colors.lightBlackBlue}` : `${theme.colors.whiteSmoke}`)};

    & > .select-wrapper {
      display: flex;
      align-items: center;

      & > .shift-multi-select {
        .multiselect__single,
        .multiselect__input,
        .multiselect__content-wrapper {
          font-size: 13px;
        }

        .multiselect__tags,
        .multiselect__single {
          background: ${({ theme }) => theme.colors.selectBG};
          color: ${({ theme }) => theme.colors.selectText};
        }
      }

      & > .text {
        margin-right: 5px;
        color: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
`

export default {
  props: {
    selectedShifts: {
      type: Array,
      required: true,
    },
    shiftFiltersEnabled: {
      type: Boolean,
      required: true,
    },
    availableShiftplans: {
      type: Array,
      required: true,
    },
    selectedShiftplan: {
      type: Object,
    },
  },
  components: {
    TimepickerShiftFiltersMolecule,
    Multiselect,
    OnOffToggle,
    ShiftAtom,
  },
  methods: {},
}
</script>
