<template>
  <TimepickerHeaderMolecule :class="{ active: isExpanded }" @click="$emit('toggle-is-exanded')">
    <FeatherIconAtom :iconName="'ClockIcon'" />
    <SelectedTimeframeExplainedAtom />
    <Badge class="badge-selected-duration" :label="durationShort" />
  </TimepickerHeaderMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { buttonReset } from '@styles/mixins'
import { Badge } from '@common/components'

import FeatherIconAtom from '@/components/Atomic/Atoms/FeatherIconAtom.vue'
import SelectedTimeframeExplainedAtom from '@/components/Atomic/Atoms/Misc/Timepicker/SelectedTimeframeExplainedAtom.vue'

const TimepickerHeaderMolecule = styled('button')`
  ${buttonReset};
  position: relative;
  z-index: 101;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : theme.colors.solidBG)};
  box-shadow: ${({ theme }) => theme.colors.widgetShadowEnforced};
  padding: 0.25rem 0.5rem;
  transition: border-radius 0.25s ease;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  @media (max-width: 767px) {
    height: 36px;
    font-size: 10px;
  }

  @media (min-width: 768px) {
    grid-template-columns: min-content 1fr min-content;
    height: 40px;
    border-radius: 0.5rem;
  }

  .feather {
    display: none;
    flex-shrink: 0;
    opacity: ${p => (p.theme.isDark ? 0.5 : 1)};

    @media (min-width: 768px) {
      display: block;
    }
  }
  &.active {
    color: ${p => p.theme.colors.primary};
    .feather {
      opacity: 1;
    }
    border-bottom-left-radius: 0;
    @media (min-width: 768px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &:hover {
    color: ${p => p.theme.colors.primaryLighter};
  }

  .badge-selected-duration {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      width: 24px;
      height: 24px;
    }
  }
`

export default {
  props: {
    selectedInterval: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TimepickerHeaderMolecule,
    FeatherIconAtom,
    SelectedTimeframeExplainedAtom,
    Badge,
  },
  computed: {
    durationShort() {
      if (!this.selectedInterval) {
        return ''
      }
      if (this.selectedInterval.length('months') >= 1) {
        return `${Math.round(this.selectedInterval.length('months'))} M`
      }
      if (this.selectedInterval.length('days') >= 1) {
        return `${Math.round(this.selectedInterval.length('days'))} d`
      }
      if (this.selectedInterval.length('hours') >= 1) {
        return `${Math.round(this.selectedInterval.length('hours'))} h`
      }
      if (this.selectedInterval.length('minutes') >= 1) {
        return `${Math.round(this.selectedInterval.length('minutes'))} m`
      }
      return `${Math.round(this.selectedInterval.length('seconds'))} s`
    },
  },
}
</script>
