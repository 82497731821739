var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GroupCategoryItemWrapperStyled', [_c('GroupCategoryItemStyled', [_c('GroupCategoryToggleStyled', {
    on: {
      "click": _vm.handleGroupItemCollapse
    }
  }, [_vm.isGroupItemExpanded ? _c('ChevronDownIcon') : _c('ChevronRightIcon'), _vm.groupedData.__typename === 'Organization' ? _c('div', [_vm._v(" " + _vm._s(_vm.groupedData.alias) + " ")]) : _vm.groupedData.__typename === 'MachineType' ? _c('div', [_vm._v(" " + _vm._s(this.$t("assetTypes.".concat(_vm.groupedData.name.toLowerCase().split(' ').join('')))) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.groupedData.name) + " ")]), _c('CountCircle', {
    staticClass: "circle",
    attrs: {
      "count": _vm.allGroupAssets.length
    }
  })], 1), _c('CheckIconWrapper', {
    on: {
      "click": _vm.toggleAllGroupAssetsShown
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.areAllGroupAssetsShown ? _c('CheckIcon') : _vm.isAnyGroupAssetShown ? _c('MinusIcon') : _vm._e()], 1)], 1)], 1), _vm.isGroupItemExpanded ? _c('div', _vm._l(_vm.allGroupAssets, function (asset) {
    return _c('AssetListItemStyled', {
      key: asset.id,
      on: {
        "click": function click($event) {
          return _vm.$emit('toggle-asset-shown', asset);
        }
      }
    }, [_c('TalpaIcon', {
      staticClass: "icon",
      attrs: {
        "scope": 'AssetTypes',
        "name": _vm.iconName(asset)
      }
    }), _vm._v(" " + _vm._s(asset.name) + " "), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [_vm.isAssetChecked(asset) ? _c('CheckIcon') : _vm._e()], 1)], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }