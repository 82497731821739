<template>
  <GroupCategoryItemWrapperStyled>
    <GroupCategoryItemStyled>
      <GroupCategoryToggleStyled @click="handleGroupItemCollapse">
        <ChevronDownIcon v-if="isGroupItemExpanded" />
        <ChevronRightIcon v-else />
        <div v-if="groupedData.__typename === 'Organization'">
          {{ groupedData.alias }}
        </div>
        <div v-else-if="groupedData.__typename === 'MachineType'">
          {{ this.$t(`assetTypes.${groupedData.name.toLowerCase().split(' ').join('')}`) }}
        </div>
        <div v-else>
          {{ groupedData.name }}
        </div>
        <CountCircle class="circle" :count="allGroupAssets.length" />
      </GroupCategoryToggleStyled>
      <CheckIconWrapper @click="toggleAllGroupAssetsShown">
        <transition name="fade">
          <CheckIcon v-if="areAllGroupAssetsShown" />
          <MinusIcon v-else-if="isAnyGroupAssetShown" />
        </transition>
      </CheckIconWrapper>
    </GroupCategoryItemStyled>
    <div v-if="isGroupItemExpanded">
      <AssetListItemStyled v-for="asset in allGroupAssets" :key="asset.id" @click="$emit('toggle-asset-shown', asset)">
        <TalpaIcon :scope="'AssetTypes'" class="icon" :name="iconName(asset)" />
        {{ asset.name }}
        <transition name="fade">
          <CheckIcon v-if="isAssetChecked(asset)" />
        </transition>
      </AssetListItemStyled>
    </div>
  </GroupCategoryItemWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { buttonReset, flexCenter, flexStartCenter } from '@styles/mixins'
import { CheckIcon, MinusIcon, ChevronRightIcon as ChevronRightIconSrc, ChevronDownIcon as ChevronDownIconSrc } from 'vue-feather-icons'
import { TalpaIcon, CountCircle } from '@common/components'
import { get, orderBy } from 'lodash'

const GroupCategoryItemWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`

const ChevronRightIcon = styled(ChevronRightIconSrc)`
  cursor: pointer;
`

const ChevronDownIcon = styled(ChevronDownIconSrc)`
  cursor: pointer;
`

const CheckIconWrapper = styled('div')`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  margin-left: 0.25rem;
  color: ${p => p.theme.colors.white};
  background: ${p => (p.theme.isDark ? p.theme.colors.primary : chroma(p.theme.colors.primaryDarkest).desaturate(1).css())};

  svg {
    width: 18px;
    height: 18px;
  }
`

const GroupCategoryToggleStyled = styled('button')`
  ${buttonReset}
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.white};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: linear-gradient(
    to right,
    ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.primary)} 0%,
    ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.darkestGrey)} 150%
  );
  flex-grow: 1;
  height: 2rem;
  &:hover {
    color: ${p => p.theme.colors.white};
  }

  .circle {
    margin-left: 0.5rem;
    color: ${p => p.theme.colors.white};
    background: ${p => chroma(p.theme.colors.white).alpha(0.2).css()};
  }
`

const GroupCategoryItemStyled = styled('div')`
  margin: 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AssetListItemStyled = styled('button')`
  ${flexStartCenter}
  ${buttonReset}
  color: ${p => p.theme.colors.primary};
  padding: 0.25rem;
  margin: 0.25rem 0.5rem;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  > .icon {
    ${flexCenter};
    color: ${p => (p.theme.isDark ? p.theme.colors.white : p.theme.colors.primary)};
    opacity: 0.65;
    svg {
      width: 28px;
      height: 28px;
    }
    margin-right: 0.5rem;
  }
  &:first-child {
    margin-top: 0.5rem;
  }
  .feather {
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
  }
`
export default {
  props: {
    groupedData: {
      type: [Array, Object],
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
    shownAssetIdsLocal: {
      type: Array,
      required: true,
    },
    expandedGroupIds: {
      type: Array,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
      default: 'site',
    },
  },
  components: {
    AssetListItemStyled,
    GroupCategoryItemStyled,
    CheckIconWrapper,
    CheckIcon,
    TalpaIcon,
    MinusIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    GroupCategoryToggleStyled,
    GroupCategoryItemWrapperStyled,
    CountCircle,
  },
  computed: {
    allGroupAssets() {
      let allAssets
      switch (this.groupId) {
        case 'site':
          allAssets = this.assets.filter(asset => asset.site.id === this.groupedData.id)
          break
        case 'oem':
          allAssets = this.assets.filter(asset => asset.oem.id === this.groupedData.id)
          break
        case 'model':
          allAssets = this.assets.filter(asset => asset.model.id === this.groupedData.id)
          break
        case 'type':
          allAssets = this.assets.filter(asset => asset.type.id === this.groupedData.id)
          break
        case 'usergroup':
          allAssets = this.assets?.filter(asset => asset?.subsidiary?.some(sub => sub.id === this.groupedData.id))
          break
      }
      return orderBy(allAssets, 'name', 'asc')
    },
    allGroupAssetIds() {
      return this.allGroupAssets.map(({ id }) => id)
    },
    shownGroupAssetIds() {
      return this.shownAssetIdsLocal.filter(id => this.allGroupAssetIds.includes(id))
    },
    areAllGroupAssetsShown() {
      return this.shownGroupAssetIds.length === this.allGroupAssetIds.length
    },
    isAnyGroupAssetShown() {
      return this.shownGroupAssetIds.length >= 1
    },
    isGroupItemExpanded() {
      return this.expandedGroupIds.includes(this.groupedData.id)
    },
  },
  methods: {
    iconName(asset) {
      return get(asset, 'type.name', '').split(' ').join('')
    },
    isAssetChecked(asset) {
      return this.shownAssetIdsLocal.includes(asset.id)
    },
    toggleAllGroupAssetsShown() {
      this.$emit('toggle-many-assets-shown', this.allGroupAssetIds)
    },
    handleGroupItemCollapse() {
      this.$emit('toggle-group-expanded', this.groupedData.id)
    },
  },
}
</script>
