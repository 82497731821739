var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetSelectorStyled', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.closeAssetList,
      expression: "closeAssetList"
    }]
  }, [_c('AssetSelectorToggle', {
    ref: "toggle",
    class: {
      active: _vm.showAssetList
    },
    on: {
      "click": _vm.toggleShowAssetList
    }
  }, [_c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": 'FleetIcon'
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('asset', _vm.selectedAssetsCount))))]), _c('CountCircle', {
    attrs: {
      "count": _vm.selectedAssetsCount
    }
  })], 1), _c('AssetListWrapperStyled', {
    attrs: {
      "offsetY": _vm.assetListOffsetY
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade-in-down"
    }
  }, [_vm.showAssetList ? _c('div', {
    staticClass: "asset-list-inner"
  }, [_c('GroupByDropdown', {
    attrs: {
      "availableSelectors": _vm.dropDownSelectors,
      "preselectionId": _vm.preselectionId
    },
    on: {
      "selectionChanged": function selectionChanged($event) {
        return _vm.groupByFilter($event);
      }
    }
  }), _c('AssetListSearchStyled', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    attrs: {
      "placeholder": _vm.$t('signals.search.placeholder')
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  })]), _c('AssetList', {
    attrs: {
      "assets": _vm.assetsFiltered,
      "allAssets": _vm.assets,
      "groupedAssetMetaData": _vm.groupedAssetMetaData,
      "groupId": _vm.selectedFilter
    },
    on: {
      "closeAssetList": _vm.closeAssetList
    }
  })], 1) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }