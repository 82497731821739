<template>
  <AssetSelectorStyled v-away="closeAssetList">
    <AssetSelectorToggle ref="toggle" @click="toggleShowAssetList" :class="{ active: showAssetList }">
      <TalpaIcon class="icon" :scope="'Misc'" :name="'FleetIcon'" />
      <span>{{ $tc('asset', selectedAssetsCount) | capitalize }}</span>
      <CountCircle :count="selectedAssetsCount" />
    </AssetSelectorToggle>
    <AssetListWrapperStyled :offsetY="assetListOffsetY">
      <transition name="fade-in-down">
        <div class="asset-list-inner" v-if="showAssetList">
          <GroupByDropdown
            :availableSelectors="dropDownSelectors"
            @selectionChanged="groupByFilter($event)"
            :preselectionId="preselectionId"
          />
          <AssetListSearchStyled>
            <input v-model="searchQuery" :placeholder="$t('signals.search.placeholder')" />
          </AssetListSearchStyled>
          <AssetList
            :assets="assetsFiltered"
            :allAssets="assets"
            :groupedAssetMetaData="groupedAssetMetaData"
            @closeAssetList="closeAssetList"
            :groupId="selectedFilter"
          />
        </div>
      </transition>
    </AssetListWrapperStyled>
  </AssetSelectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { directive as onClickaway } from 'vue-clickaway'
import uniqBy from 'lodash/uniqBy'
import { TalpaIcon, CountCircle } from '@common/components'
import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'
import AssetList from './AssetSelector/AssetsSelectorList'
import GroupByDropdown from '@/components/Atomic/Molecules/GroupByFilterMolecule'
import { orderBy } from 'lodash'
import { useAssetStore } from '@/stores/assets'

import MY_ASSETS_SITE_QUERY from '#/graphql/operations/assets/minimals/myAssetsSite.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'
import MY_SUBSIDIARIES_WITH_ASSETS_QUERY from '#/graphql/operations/subsidiaries/mySubsidiariesWithAssetsQuery.gql'

const AssetSelectorStyled = styled('div')`
  width: 360px;
  max-width: 50%;
`

const AssetSelectorToggle = styled('button')`
  ${flexCenter}
  ${buttonReset}
  width: 100%;
  height: 40px;
  color: ${p => p.theme.colors.primary};
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  padding: 0.5rem 1rem;
  padding-left: 0.5rem;
  margin-right: 1px;
  transition: border-radius 0.25s ease;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  @media (max-width: 767px) {
    height: 36px;
    font-size: 10px;
  }

  @media (min-width: 768px) {
    border-radius: 0.5rem;
  }

  .icon {
    display: none;
    margin-left: 0.5rem;
    flex-shrink: 0;
    opacity: ${p => (p.theme.isDark ? 0.5 : 1)};
    width: 24px;
    height: 24px;

    @media (min-width: 768px) {
      display: block;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  span {
    flex-grow: 1;
  }
  .feather {
    display: none;
    flex-shrink: 0;
    opacity: 0.5;

    @media (min-width: 768px) {
      display: block;
    }
  }
  &.active {
    color: ${p => p.theme.colors.primary};
    .feather,
    .icon {
      opacity: 1;
    }
    border-bottom-right-radius: 0;
    @media (min-width: 768px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &:hover {
    color: ${p => p.theme.colors.primaryLighter};
  }
`

const AssetListWrapperStyled = styled('div')`
  position: absolute;
  width: 358px;
  max-width: calc(100vw - 52px);
  z-index: 100;
  @media (max-width: 767px) {
    left: 0;
  }
  .asset-list-inner {
    ${flexColumns}
    width: calc(100% - .5rem - 2px);
    @media (min-width: 768px) {
      width: calc(100% - 0.5rem);
    }
    margin-top: 0.1rem;
    border: 1px solid ${p => (p.theme.isDark ? p.theme.colors.darkerGrey : p.theme.colors.white)};
    border-top: none;
    background: ${p => p.theme.colors.solidBG};
    padding: 0.5rem 0.25rem 0.25rem 0.25rem;
    box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  }
`

const AssetListSearchStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  input {
    width: calc(100% - 2rem);
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.inputOnSolid};
    border-bottom: 1px solid ${props => chroma(props.theme.colors.white).alpha(0.8).css()};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    AssetSelectorToggle,
    CountCircle,
    AssetList,
    AssetSelectorStyled,
    AssetListWrapperStyled,
    AssetListSearchStyled,
    TalpaIcon,
    GroupByDropdown,
  },
  data() {
    return {
      showAssetList: false,
      assetListOffsetY: 0,
      searchQuery: '',
      selectedFilter: '',
      assetsSiteMap: new Map(),
      oemsMap: new Map(),
      mySubsidiaries: [],
    }
  },
  mounted() {
    const filterMap = {
      map: 'mapAssetGroupByFilter',
      reporting: 'reportAssetGroupByFilter',
      dashboard: 'assetGroupByFilter',
    }

    const routeName = this.$route?.name
    this.selectedFilter = this.assetStore[filterMap[routeName]]
  },
  directives: {
    away: onClickaway,
  },
  computed: {
    assets() {
      if (this.$route.name === 'dashboard') {
        return this.assetStore.getDashboardAssets()
      } else {
        return this.assetStore.allAssets
      }
    },
    selectedAssetsCount() {
      const selectedMethodMap = {
        map: 'assetsSelectedInMap',
        reporting: 'assetsSelectedInReport',
        dashboard: 'assetsSelected',
      }

      const selectedMethod = selectedMethodMap[this.$route?.name] || 'assetsSelected'

      return this.assetStore[selectedMethod]().length
    },
    dropDownSelectors() {
      return [
        { id: 'site', label: this.$tc('site', 1) },
        { id: 'type', label: this.$tc('type', 1) },
        { id: 'model', label: this.$tc('model', 1) },
        { id: 'oem', label: this.$tc('oem', 1) },
        { id: 'usergroup', label: this.$tc('subsidiary', 1) },
      ]
    },
    preselectionId() {
      return this.selectedFilter
    },
    assetsFiltered() {
      const assets =
        this.searchQuery !== ''
          ? this.assets.filter(asset => asset.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
          : this.assets
      if (this.$route.name === 'dashboard') {
        return assets
      } else {
        return assets.map(({ model, type, ...asset }) => {
          const oem = this.oemsMap.get(asset.manufacturerCuid)
          const site = this.assetsSiteMap.get(asset.id)
          const subsidiaries =
            this.mySubsidiaries
              ?.filter(sub => sub.assets.some(subAsset => subAsset.id === asset.id))
              .map(sub => ({ id: sub.id, name: sub.name })) || []
          const siteToShow = site
            ? { ...site, id: site.siteId }
            : { id: 'unknown', name: this.$tc('unknownSite', 1), __typename: 'AssetSite' }
          const modelToShow = model || { id: 'unknown', name: this.$t('unknownModel'), __typename: 'MachineModel' }
          const oemToShow = oem || { id: 'unknown', name: this.$t('unknownOEM'), alias: this.$t('unknownOEM'), __typename: 'OEM' }
          const typeToShow = type || { id: 'unknown', name: this.$t('unknownType'), __typename: 'MachineType' }
          return {
            subsidiary: subsidiaries.length
              ? subsidiaries
              : [{ id: 'unknown', name: this.$t('unknownUsergroup'), __typename: 'Subsidiary' }],

            site: siteToShow,
            model: modelToShow,
            oem: oemToShow,
            type: typeToShow,
            ...asset,
          }
        })
      }
    },
    groupedAssetMetaData() {
      let groupedAssets
      switch (this.selectedFilter) {
        case 'site':
          groupedAssets = this.assetsFiltered.map(({ site }) => site)
          break
        case 'oem':
          groupedAssets = this.assetsFiltered.map(({ oem }) => oem)
          break
        case 'model':
          groupedAssets = this.assetsFiltered.map(({ model }) => model)
          break
        case 'type':
          groupedAssets = this.assetsFiltered.map(({ type }) => type)
          break
        case 'usergroup':
          groupedAssets = this.assetsFiltered.map(({ subsidiary }) => subsidiary)
          break
      }
      const groupedData = uniqBy(this.selectedFilter === 'usergroup' ? groupedAssets?.flat() : groupedAssets, 'id')
      return orderBy(groupedData, 'name', 'asc')
    },
  },
  methods: {
    toggleShowAssetList() {
      if (this.assets.length) {
        this.showAssetList = !this.showAssetList
        if (this.$refs.toggle) {
          const bounds = this.$refs.toggle.getBoundingClientRect()
          this.assetListOffsetY = bounds.y + bounds.height
        }
      }
    },
    closeAssetList() {
      this.showAssetList = false
    },
    groupByFilter(option) {
      this.selectedFilter = option?.id
      const setFilterMethodMap = {
        map: 'setMapAssetGroupByFilter',
        reporting: 'setReportAssetGroupByFilter',
        dashboard: 'setAssetGroupByFilter',
      }

      const setFilterMethod = setFilterMethodMap[this.$route?.name]

      if (setFilterMethod) {
        this.assetStore[setFilterMethod](option?.id)
      }
    },
  },
  apollo: {
    myAssetsSite: {
      query: MY_ASSETS_SITE_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
      },
      skip() {
        return this.$route.name === 'dashboard'
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
      },
      skip() {
        return this.$route.name === 'dashboard'
      },
    },
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_WITH_ASSETS_QUERY,
      skip() {
        return this.$route.name === 'dashboard'
      },
    },
  },
}
</script>
