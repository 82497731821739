var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimepickerMolecule', {
    attrs: {
      "selectedInterval": _vm.timepickerStore.selectedInterval,
      "availableTimezones": _vm.timepickerStore.availableTimezones,
      "selectedTimezone": _vm.timepickerStore.selectedTimezone,
      "selectedTimezoneId": _vm.timepickerStore.selectedTimezoneId,
      "hasShiftFilter": !!_vm.shiftsReadPermission,
      "shiftFiltersEnabled": _vm.timepickerStore.shiftFiltersEnabled,
      "availableShiftplans": _vm.timepickerStore.$myShiftplans,
      "selectedShiftplan": _vm.timepickerStore.selectedShiftplan,
      "selectedShifts": _vm.timepickerStore.selectedShifts,
      "futureDateDisabled": _vm.futureDateDisabled,
      "maxSelectableIntervalInDays": _vm.maxSelectableIntervalInDays
    },
    on: {
      "update-settings": _vm.updateSettings
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }