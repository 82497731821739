<template>
  <TimepickerTimezoneMolecule>
    <span class="label">{{ $t('timezone.timezone') }}</span>
    <Multiselect
      :value="selectedTimezoneOption"
      :multiple="false"
      open-direction="above"
      :max-height="200"
      :options="availableTimezonesMapped"
      :showLabels="false"
      :placeholder="$t('timezone.selectTimezonePlaceholder')"
      trackBy="id"
      label="label"
      @select="$emit('set-selected-timezone', $event)"
      class="shift-multi-select"
    />
  </TimepickerTimezoneMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'

const TimepickerTimezoneMolecule = styled('div')`
  position: relative;
  overflow: visible;
  display: flex;
  padding: 10px 15px 0px 15px;
  background: ${p => p.theme.colors.solidBG};
  height: 40px;
  align-items: center;
  font-size: 13px;
  .label {
    width: max-content;
    margin-right: 5px;
  }
  .multiselect {
    &.shift-multi-select {
      .multiselect__single,
      .multiselect__input,
      .multiselect__content-wrapper {
        font-size: 13px;
      }
      .multiselect__content-wrapper {
        z-index: 10000;
      }

      .multiselect__tags,
      .multiselect__single {
        background: ${({ theme }) => theme.colors.selectBG};
        color: ${({ theme }) => theme.colors.selectText};
      }
    }

    & > .text {
      margin-right: 5px;
      color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`

export default {
  props: {
    localSelectedTimezoneOption: {
      type: Object,
      required: true,
    },
    availableTimezones: {
      type: Array,
      required: true,
    },
  },
  components: {
    TimepickerTimezoneMolecule,
    Multiselect,
  },
  computed: {
    selectedTimezoneOption() {
      return this.availableTimezonesMapped.find(f => f.id === this.localSelectedTimezoneOption.id)
    },
    availableTimezonesMapped() {
      return this.availableTimezones.map(m => ({
        ...m,
        label: m.label.replace('<browser>', this.$t('timezone.browser.title')),
      }))
    },
  },
}
</script>
