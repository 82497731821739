<template>
  <MonthsMolecule viewBox="0 0 50 350" @click.prevent.stop="activateMonth">
    <PolygonStyled v-for="month in months" :key="month.id" :points="month.points" :order="month.order" :isLargest="month.isLargest" />
    <TextStyled
      v-for="month in months"
      :key="month.id + '_text-month'"
      :x="25"
      :y="month.textY - 10"
      :order="month.order"
      text-anchor="middle"
      :height="month.height"
    >
      {{ month.date.toFormat('LLL', { locale: locale }) }}
    </TextStyled>
    <TextStyled
      v-for="month in months"
      :key="month.id + '_text-year'"
      :x="25"
      :y="month.textY + 10"
      :order="month.order"
      text-anchor="middle"
      :height="month.height"
    >
      {{ month.date.toFormat('yy') }}
    </TextStyled>
    <PolygonTransparent
      v-for="month in months"
      :key="month.id + '_transparent'"
      :points="month.points"
      :order="month.order"
      :data-date="month.dateFormatted"
      :isLargest="month.isLargest"
    />
  </MonthsMolecule>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
const MonthsMolecule = styled('svg')`
  width: 100%;
  height: 100%;
  grid-column-start: 9;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 8;
`

const PolygonStyled = styled('polygon')`
  fill: ${props => (props.isLargest ? props.theme.colors.primary : chroma(props.theme.colors.primary).darken(1).css())};
  transition: fill 0.5s;
  &:hover {
    fill: ${props => props.theme.colors.primaryLighter};
  }
`

const PolygonTransparent = styled('polygon')`
  fill: transparent;
`

const TextStyled = styled('text')`
  fill: ${props => props.theme.colors.white};
  opacity: ${props => (props.height === 1 ? 0 : 1)};
`

export default {
  inject: ['uiSettings'],
  props: {
    firstMondayInView: {
      type: Object,
      required: true,
    },
    localSelectedTimezoneOption: {
      type: Object,
      required: true,
    },
    futureDateDisabled: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    MonthsMolecule,
    PolygonStyled,
    PolygonTransparent,
    TextStyled,
  },
  data() {
    return {
      width: 50,
    }
  },
  watch: {
    months: {
      handler() {
        this.setupHandlers()
      },
    },
  },
  mounted() {
    this.setupHandlers()
  },
  destroyed() {
    const polygons = this.$el.getElementsByTagName('polygon')
    if (!polygons) {
      return
    }
    for (const polygon of polygons) {
      polygon.removeEventListener('mouseover', this.setHighlightedInterval)
      polygon.removeEventListener('mouseleave', this.unsetHighlightedInterval)
    }
  },
  computed: {
    months() {
      let position = 0
      let order = 1
      let largest = null
      const months = Array.from(Array(6).keys()).reduce((acc, i) => {
        const monday = this.firstMondayInView.plus({ weeks: i })
        const sunday = this.firstMondayInView.plus({ weeks: i, days: 6 })

        const id = `month-${monday.year}-${monday.month}`
        const month = acc.find(m => m.id === id)
        if (month) {
          month.height += 1
          month.hasSharedEnd = monday.month !== sunday.month
        } else {
          acc.push({
            id,
            position,
            order,
            date: monday,
            height: order === 1 ? 2 : 1,
            hasSharedStart: false,
            hasSharedEnd: monday.month !== sunday.month,
          })
          order += 1
        }
        if (monday.month !== sunday.month) {
          acc.push({
            id: `month-${sunday.year}-${sunday.month}`,
            position,
            order,
            date: sunday,
            height: order === 1 ? 2 : 1,
            hasSharedStart: true,
            hasSharedEnd: false,
          })
          order += 1
        }
        position += 1
        return acc
      }, [])

      months.forEach(m => {
        if (!largest || largest.height < m.height) {
          largest = m
        }
      })

      return months.map(m => {
        const offsetY = 50 * m.position + (m.order > 1 ? 50 : 0)
        return {
          ...m,
          dateFormatted: m.date.toISO(),
          points: `
            0 ${(m.hasSharedStart ? 50 : 0) + offsetY}
            50 ${offsetY}
            50 ${(m.hasSharedEnd ? 50 : 0) + offsetY + 50 * m.height}
            0 ${offsetY + 50 * m.height}
          `,
          isLargest: m.id === largest.id,
          // textY: offsetY + (25 * m.height) - (order === 1 ? 50 : 0) - (order === 3 ? 25 : 0),
          textY: offsetY + 25 * m.height + (m.hasSharedStart && m.height === 2 ? 25 : 0),
        }
      })
    },
    locale() {
      return (this.uiSettings?.dates ?? '').toLowerCase().replace('_', '-')
    },
  },
  methods: {
    activateMonth(e) {
      const date = e?.target?.dataset?.date ?? null

      if (date) {
        const dt = DateTime.fromISO(date).setZone(this.localSelectedTimezoneOption.tz)
        if (this.futureDateDisabled && dt > DateTime.now()) {
          return false
        }
        let end = dt.endOf('month')
        if (this.futureDateDisabled && end > DateTime.now()) {
          end = DateTime.now().endOf('day')
        }
        const interval = Interval.fromDateTimes(dt.startOf('month'), end)
        this.$emit('updateSelectionToInterval', interval)
      }
      return false
    },
    setHighlightedInterval(e) {
      const date = e?.target?.dataset?.date ?? null
      if (date) {
        const dt = DateTime.fromISO(date).setZone(this.localSelectedTimezoneOption.tz)
        let end = dt.endOf('month')
        if (this.futureDateDisabled && end > DateTime.now()) {
          end = DateTime.now().endOf('day')
        }
        const interval = Interval.fromDateTimes(dt.startOf('month'), end)
        this.$emit('setHighlightedInterval', interval)
      }
    },
    unsetHighlightedInterval() {
      this.$emit('setHighlightedInterval', null)
    },
    setupHandlers() {
      if (!this.$el) {
        return
      }
      const polygons = this.$el.getElementsByTagName('polygon')
      if (!polygons) {
        return
      }
      for (const polygon of polygons) {
        polygon.addEventListener('mouseover', this.setHighlightedInterval)
        polygon.addEventListener('mouseleave', this.unsetHighlightedInterval)
      }
    },
  },
}
</script>
