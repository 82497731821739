<template>
  <CalendarMolecule>
    <HeaderItemsMolecule>
      <HeaderItemAtom :label="$t('dashboard.time.calendarWeek')" :isCW="true" />
      <HeaderItemAtom v-for="(name, i) in weekdayNames" :key="i" :label="name" />
    </HeaderItemsMolecule>
    <CalendarWeeksMolecule
      :calendarWeeks="calendarWeeks"
      :localSelectedTimezoneOption="localSelectedTimezoneOption"
      :futureDateDisabled="futureDateDisabled"
      @setHighlightedInterval="$emit('setHighlightedInterval', $event)"
      @updateSelectionToInterval="$emit('updateSelectionToInterval', $event)"
    />
    <DaysMolecule
      :days="days"
      :isSelecting="isSelecting"
      :hasSelection="hasSelection"
      :highlightedInterval="highlightedInterval"
      :selectedInterval="selectedInterval"
      :localSelectedInterval="localSelectedInterval"
      :localSelectedTimezoneOption="localSelectedTimezoneOption"
      @handleMouseWheel="$emit('handleMouseWheel', $event)"
      @mousedown="$emit('mousedown', $event)"
      @mousemove="$emit('mousemove', $event)"
      @mouseLeave="$emit('mouseLeave', $event)"
      @touchStart="$emit('touchStart', $event)"
      @touchEnd="$emit('touchEnd', $event)"
      @handleHover="$emit('handleHover', $event)"
      @touchMove="$emit('touchMove', $event)"
    />
    <MonthsMolecule
      :firstMondayInView="firstMondayInView"
      :localSelectedTimezoneOption="localSelectedTimezoneOption"
      :futureDateDisabled="futureDateDisabled"
      @setHighlightedInterval="$emit('setHighlightedInterval', $event)"
      @updateSelectionToInterval="$emit('updateSelectionToInterval', $event)"
    />
  </CalendarMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import HeaderItemAtom from '@/components/Atomic/Atoms/Misc/Timepicker/HeaderItemAtom.vue'
import CalendarWeeksMolecule from './CalendarMolecule/CalendarWeeksMolecule.vue'
import DaysMolecule from './CalendarMolecule/DaysMolecule.vue'
import HeaderItemsMolecule from './CalendarMolecule/HeaderItemsMolecule.vue'
import MonthsMolecule from './CalendarMolecule/MonthsMolecule.vue'
import { DateTime } from 'luxon'

const CalendarMolecule = styled('div')`
  width: 100%;
  max-width: calc(100vw - 52px);
  display: grid;

  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(7, 1fr);
  border-bottom: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : theme.colors.atomic.bunkerLight)};
  @media (min-width: 768px) {
    grid-template-columns: repeat(9, 40px);
    grid-template-rows: repeat(7, 40px);
  }
  grid-auto-flow: row dense;
`

export default {
  inject: ['uiSettings'],
  props: {
    firstMondayInView: {
      type: Object,
      required: true,
    },
    selectionStart: {
      type: String,
    },
    selectionEnd: {
      type: String,
    },
    isSelecting: {
      type: Boolean,
    },
    hasSelection: {
      type: Boolean,
    },
    highlightedInterval: {
      type: Object,
    },
    localShiftFiltersEnabled: {
      type: Boolean,
      required: true,
    },
    localSelectedShifts: {
      type: Array,
      required: true,
    },
    localSelectedInterval: {
      type: Object,
    },
    localSelectedTimezoneOption: {
      type: Object,
      required: true,
    },
    selectableDateInterval: {
      type: Object,
      required: false,
    },
    selectedInterval: {
      type: Object,
    },
    selectedShifts: {
      type: Array,
      required: true,
    },
    selectedTimezone: {
      type: String,
      required: true,
    },
    hasShiftFilter: {
      type: Boolean,
      required: true,
    },
    canConfirm: {
      type: Boolean,
      required: true,
    },
    canGoPrev: {
      type: Boolean,
      required: true,
    },
    canGoNext: {
      type: Boolean,
      required: true,
    },
    futureDateDisabled: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CalendarMolecule,
    HeaderItemsMolecule,
    HeaderItemAtom,
    DaysMolecule,
    CalendarWeeksMolecule,
    MonthsMolecule,
  },

  computed: {
    locale() {
      return (this.uiSettings?.dates ?? '').toLowerCase().replace('_', '-')
    },
    weekdayNames() {
      const locale = this.locale
      return Array.from(Array(7).keys()).map(i => {
        return this.firstMondayInView.plus({ days: i }).setLocale(locale).toLocaleString({
          weekday: 'short',
        })
      })
    },
    calendarWeeks() {
      return Array.from(Array(6).keys()).map(i => {
        const date = this.firstMondayInView.plus({ days: i * 7 })
        const id = `cw-${date.year}-${date.weekNumber}`
        const isSelectable =
          this.futureDateDisabled && date.toMillis() <= DateTime.now().setZone(this.selectedTimezone).endOf('day').toMillis()
        return {
          id,
          date,
          isSelectable,
        }
      })
    },
    days() {
      return Array.from(Array(7 * 6).keys()).map(i => {
        const date = this.firstMondayInView.plus({ days: i })
        const id = `day-${date.toLocaleString()}`
        const isDateinInterval = this.selectableDateInterval ? this.selectableDateInterval.contains(date) : true
        const isSelectable = date.toMillis() <= DateTime.now().setZone(this.selectedTimezone).endOf('day').toMillis() && isDateinInterval
        return {
          id,
          date,
          isSelectable,
        }
      })
    },
  },
}
</script>
