<template>
  <AssetListStyled>
    <AssetListItemsStyled ref="groupedAssetList">
      <ButtonWrapperStyled>
        <SelectButtonStyled
          v-for="button in selectionButtons"
          :key="button.id"
          @click="selectDeselectAll(button.id)"
          :disabled="button.id === 'select' ? disableSelectAll : !!canDeselectAll"
        >
          <CheckCircleIcon v-if="button.id === 'select'" size="1.45x" />
          <MinusCircleIcon v-else size="1.45x" />
          <div class="name">
            {{ $tc(`actions.${button.label}`) }}
          </div>
        </SelectButtonStyled>
      </ButtonWrapperStyled>
      <InfoTabStyled v-if="canDeselectAll"><InfoIcon size="1.5x" /> {{ $tc('messages.assetSelector') }}</InfoTabStyled>
      <AssetsSelectorListItem
        v-for="groupedData in groupedAssetMetaData"
        :groupedData="groupedData"
        :assets="assets"
        :expandedGroupIds="expandedGroupIds"
        :shownAssetIdsLocal="shownAssetIdsLocal"
        :key="groupedData.id"
        :groupId="groupId"
        @toggle-asset-shown="toggleAssetShown"
        @toggle-many-assets-shown="toggleManyAssetsShown"
        @toggle-group-expanded="toggleGroupExpanded"
      />
    </AssetListItemsStyled>
    <ButtonsSection>
      <ButtonStyleless @click="setHiddenAssetsToStore" :disabled="!canApply">
        <CheckIcon />
        {{ $t('actions.apply') }}
      </ButtonStyleless>
      <ButtonStyleless @click="resetShownAssets" :disabled="!canCancel">
        <XIcon />
        {{ $t('actions.cancel') }}
      </ButtonStyleless>
    </ButtonsSection>
  </AssetListStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import AssetsSelectorListItem from './AssetsSelectorListItem'
import { isEqual, difference } from 'lodash'
import { CheckIcon, XIcon, CheckCircleIcon, MinusCircleIcon, InfoIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'
import { buttonReset, flexCenter, flexColumns } from '@styles/mixins'
import { useAssetStore } from '@/stores/assets'

const ButtonStyleless = styled('button')`
  ${buttonReset}
  ${flexCenter}
  flex-grow: 1;
  height: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryActive};
  }
  &:disabled {
    color: ${props => props.theme.colors.muted};
    opacity: 0.5;
  }
`

const AssetListStyled = styled('div')`
  ${flexColumns};
  max-width: 100vw;
  max-height: 60vh;
  position: relative;
`

const AssetListItemsStyled = styled('div')`
  overflow: auto;
`

const ButtonsSection = styled('div')`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.solidBG};
`
const SelectButtonStyled = styled('button')`
  ${buttonReset}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.colors.white};
  border-radius: 3px;
  background: ${p => chroma(p.theme.colors.primary).alpha(0.8).css()};
  height: 2rem;
  &:hover:enabled {
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.primary};
  }
  font-weight: Bold;
  width: 9rem;
  .name {
    padding: 0.5rem;
  }
`
const ButtonWrapperStyled = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-between;
`

const InfoTabStyled = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 14px;
  color: ${p => p.theme.colors.primary};
  > svg {
    padding: 0.5rem;
  }
`
export default {
  props: {
    groupedAssetMetaData: {
      type: Array,
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
    allAssets: {
      type: Array,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
      default: 'site',
    },
  },
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    AssetListStyled,
    AssetListItemsStyled,
    AssetsSelectorListItem,
    ButtonsSection,
    ButtonStyleless,
    CheckIcon,
    XIcon,
    CheckCircleIcon,
    MinusCircleIcon,
    SelectButtonStyled,
    ButtonWrapperStyled,
    InfoTabStyled,
    InfoIcon,
  },
  data() {
    return {
      shownAssetIdsInitial: [],
      shownAssetIdsLocal: [],
      expandedGroupIds: [],
      selectionButtons: [
        { id: 'select', label: 'selectAll' },
        { id: 'deselect', label: 'deselectAll' },
      ],
    }
  },
  watch: {
    groupedAssetMetaData: {
      handler() {
        this.scrollTop()
      },
    },
  },
  computed: {
    canCancel() {
      const sortedAssetIdsLocal = [...this.shownAssetIdsLocal].sort()
      const sortedAssetIdsInitial = [...this.shownAssetIdsInitial].sort()
      return !isEqual(sortedAssetIdsLocal, sortedAssetIdsInitial)
    },
    canApply() {
      return this.canCancel && this.shownAssetIdsLocal.length > 0
    },
    disableSelectAll() {
      const totalAssets = this.assets.map(asset => asset.id)
      const localAssets = this.shownAssetIdsLocal
      const result = isEqual(totalAssets.sort(), localAssets.sort())
      return !!result
    },
    canDeselectAll() {
      return this.shownAssetIdsLocal.length === 0
    },
  },
  methods: {
    toggleManyAssetsShown(assetIds) {
      const shownAssetIds = this.shownAssetIdsLocal.filter(id => assetIds.includes(id))
      if (shownAssetIds.length) {
        this.shownAssetIdsLocal = this.shownAssetIdsLocal.filter(id => !shownAssetIds.includes(id))
      } else {
        assetIds.forEach(id => {
          if (!this.shownAssetIdsLocal.includes(id)) {
            this.shownAssetIdsLocal.push(id)
          }
        })
      }
    },
    toggleAssetShown(asset) {
      if (this.shownAssetIdsLocal.includes(asset.id)) {
        this.shownAssetIdsLocal = this.shownAssetIdsLocal.filter(id => id !== asset.id)
      } else {
        this.shownAssetIdsLocal.push(asset.id)
      }
    },
    toggleGroupExpanded(groupId) {
      if (this.expandedGroupIds.includes(groupId)) {
        this.expandedGroupIds = this.expandedGroupIds.filter(id => id !== groupId)
      } else {
        this.expandedGroupIds.push(groupId)
      }
    },
    resetShownAssets() {
      this.shownAssetIdsLocal = [...this.shownAssetIdsInitial]
    },
    setHiddenAssetsToStore() {
      const allAssetIds = this.allAssets.map(({ id }) => id)
      const shownAssetIds = this.shownAssetIdsLocal
      const hiddenAssetIds = difference(allAssetIds, shownAssetIds)
      let setHiddenAssetIdsMethod
      switch (this.$route?.name) {
        case 'map':
          setHiddenAssetIdsMethod = 'setMapHiddenAssetIds'
          break
        case 'reporting':
          setHiddenAssetIdsMethod = 'setReportHiddenAssetIds'
          break
        default:
          setHiddenAssetIdsMethod = 'setHiddenAssetIds'
      }

      this.assetStore[setHiddenAssetIdsMethod](hiddenAssetIds)

      this.$emit('closeAssetList')
    },
    selectDeselectAll(id) {
      const shownAssetIds = this.allAssets.map(asset => asset.id)
      this.shownAssetIdsLocal = id === 'deselect' ? [] : shownAssetIds
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.groupedAssetList.scrollTop = 0
      })
    },
  },
  mounted() {
    let hiddenAssetIds = []
    switch (this.$route?.name) {
      case 'map':
        hiddenAssetIds = this.assetStore.hiddenMapAssetIds
        break
      case 'reporting':
        hiddenAssetIds = this.assetStore.hiddenReportAssetIds
        break
      default:
        hiddenAssetIds = this.assetStore.hiddenAssetIds
    }

    const shownAssetIds = this.allAssets.filter(asset => !hiddenAssetIds.includes(asset.id)).map(({ id }) => id)

    this.shownAssetIdsInitial = [...shownAssetIds]
    this.shownAssetIdsLocal = [...shownAssetIds]
    this.expandedGroupIds = this.groupedAssetMetaData.length === 1 ? [this.groupedAssetMetaData[0].id] : []
  },
}
</script>
