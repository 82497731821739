<template>
  <TimepickerMolecule
    :selectedInterval="timepickerStore.selectedInterval"
    :availableTimezones="timepickerStore.availableTimezones"
    :selectedTimezone="timepickerStore.selectedTimezone"
    :selectedTimezoneId="timepickerStore.selectedTimezoneId"
    :hasShiftFilter="!!shiftsReadPermission"
    :shiftFiltersEnabled="timepickerStore.shiftFiltersEnabled"
    :availableShiftplans="timepickerStore.$myShiftplans"
    :selectedShiftplan="timepickerStore.selectedShiftplan"
    :selectedShifts="timepickerStore.selectedShifts"
    :futureDateDisabled="futureDateDisabled"
    :maxSelectableIntervalInDays="maxSelectableIntervalInDays"
    @update-settings="updateSettings"
  />
</template>

<script>
import permissionsMixin from '@/mixins/permissions'

import TimepickerMolecule from '@/components/Atomic/Molecules/Misc/TimepickerMolecule.vue'
import { useStore } from '@/stores/timepicker'

export default {
  mixins: [permissionsMixin],
  components: {
    TimepickerMolecule,
  },
  props: {
    futureDateDisabled: {
      type: Boolean,
      default: true,
    },
    maxSelectableIntervalInDays: {
      type: Number,
    },
  },
  setup() {
    const timepickerStore = useStore()
    return {
      timepickerStore,
    }
  },
  watch: {
    $route: {
      handler(route) {
        const selectedTimezoneId = route.query?.tz ?? 'local'
        const settings = {
          selectedIntervalIso: route.query?.interval,
          selectedTimezoneId,
          selectedShiftplanId: route.query?.shiftplan,
          selectedShiftIds: route.query?.shifts ?? [],
        }
        this.timepickerStore.updateSettings(settings)
      },
      immediate: true,
    },
  },
  methods: {
    updateSettings(settings) {
      const query = {
        // don't overwrite other query params
        ...this.$route.query,
        interval: undefined,
        tz: undefined,
        shiftplan: undefined,
        shifts: undefined,
      }
      if (settings.selectedInterval && settings.selectedInterval.toISO() !== this.timepickerStore.defaultInterval.toISO()) {
        query.interval = settings.selectedInterval.toISO()
      }
      if (settings.shiftFiltersEnabled) {
        if (settings.selectedShiftplan) {
          query.shiftplan = settings.selectedShiftplan?.id
        }
        if (settings.selectedShifts.length > 0) {
          query.shifts = settings.selectedShifts.map(m => m.id)
        }
      }
      if (settings.selectedTimezoneId && settings.selectedTimezoneId !== this.timepickerStore.defaultTimezoneId) {
        query.tz = settings.selectedTimezoneId
      }
      this.$router.push({
        query,
      })
    },
  },
}
</script>
